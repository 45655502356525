import React from 'react';
import { makeStyles, ThemeProvider, withStyles } from '@material-ui/core/styles';
import ThemeOption from '../../../services/themeOption';
import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CustomSwitchComponent from './CustomSwitchComponent';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    alignItems: 'center',
  },
  blockSwitch: {
    borderBottom: '1px solid rgba(0, 0, 0, .1)',
  },
  icon: {
    fontSize: '28px',
    color: '#BDBDBD',
    paddingRight: '5px',
  },
}));

const ExpansionPanel = withStyles({
  root: {
    border: '0px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    marginLeft: '8px',
    backgroundColor: '#F4F7FA',
    borderBottom: '1px solid rgba(0, 0, 0, .2)',
    marginBottom: 0,
    minHeight: 52,
    '&$expanded': {
      minHeight: 52,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

function GroupSirvicesItem({ titleGroup, servicesList, agentServices, agentId }) {
  const classes = useStyles();

  const listItems = servicesList.map((service) => {
    return (
      <div key={service.id} className={classes.blockSwitch}>
        <CustomSwitchComponent service={service} agentServices={agentServices} agentId={agentId} />
      </div>
    );
  });

  return (
    <ThemeProvider theme={ThemeOption}>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <AssignmentTurnedInIcon className={classes.icon} />
          <Typography className={classes.heading}>{titleGroup}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FormControl component="fieldset">
            <FormGroup aria-label="position" column="true">
              {listItems}
            </FormGroup>
          </FormControl>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </ThemeProvider>
  );
}

export default GroupSirvicesItem;
