import React, { useContext } from 'react';
import { Grid, MenuItem, Select, FormControl, FormHelperText, InputLabel, Tooltip, Box } from '@material-ui/core';
import { useFormikContext } from 'formik';
import DataContext from '../../../context/DataProvider/DataContext';
import { AgentInputType } from '../../../types/AgentInputType.types';
import DebounceTextField from '../../../components/DebounceTextfield';
import { AgentStatus } from '../../../types/Agent.types';
import Agentv2 from '../../../types/Agentv2.types';
import AgentInit from '../../../types/initValues/Agent.init';
import handleFormChange from '../services/handleFormChange';

function AgentFormMainInfo() {
  const { rank, level, brachOffices, contractType, agents } = useContext(DataContext);
  const { values, handleChange, errors, handleBlur, initialValues } = useFormikContext<AgentInputType | Agentv2>();

  const getManager = agents.filter((agent) => agent.rank.is_manager);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FormControl
          fullWidth
          variant="standard"
          error={Boolean(errors.rank_id) || false}
          disabled={initialValues.status_id === AgentStatus.dismissed}
        >
          <InputLabel>Rang</InputLabel>
          <Select
            name="rank_id"
            value={values.rank_id || ''}
            onChange={(e) => {
              handleChange(e);
              handleFormChange(values);
            }}
            onBlur={handleBlur}
            variant="standard"
          >
            {rank?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.title}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{errors.rank_id}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl
          fullWidth
          variant="standard"
          error={Boolean(errors.level_id) || false}
          disabled={initialValues.status_id === AgentStatus.dismissed}
        >
          <InputLabel>Level</InputLabel>
          <Select
            name="level_id"
            value={values.level_id || ''}
            onChange={(e) => {
              handleChange(e);
              handleFormChange(values);
            }}
            variant="standard"
          >
            {level?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.title}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{errors.level_id}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl
          disabled={values.agent_id !== AgentInit.agent_id}
          fullWidth
          variant="standard"
          error={Boolean(errors.branch_office_id) || false}
        >
          <InputLabel>Firma</InputLabel>
          <Select
            name="branch_office_id"
            value={values.branch_office_id || ''}
            onChange={(e) => {
              handleChange(e);
              handleFormChange(values);
            }}
            variant="standard"
          >
            {brachOffices?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.title}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{errors.branch_office_id}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl
          fullWidth
          variant="standard"
          error={Boolean(errors.contract_type_id) || false}
          disabled={initialValues.status_id === AgentStatus.dismissed}
        >
          <InputLabel>Vertragsart</InputLabel>
          <Select
            name="contract_type_id"
            value={values.contract_type_id || ''}
            onChange={(e) => {
              handleChange(e);
              handleFormChange(values);
            }}
            variant="standard"
          >
            {contractType?.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  value={item.id}
                  disabled={item.id === 3 && values.branch_office_id !== 1 ? true : false}
                >
                  <span>{item.title}</span>
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{errors.contract_type_id}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item>
        <DebounceTextField
          type="number"
          label="myJACK Unit"
          name="jackplus_id"
          aria-label="jackplus_id"
          value={values.jackplus_id || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          disabled={initialValues.status_id === AgentStatus.active || initialValues.status_id === AgentStatus.dismissed}
          helperText={errors.jackplus_id}
          error={Boolean(errors.jackplus_id) || false}
          fullWidth
        />
      </Grid>
      <Grid item>
        <DebounceTextField
          type="number"
          label="Kreditorennummer"
          name="agent_account_nr"
          aria-label="agent_account_nr"
          value={values.agent_account_nr || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          disabled={initialValues.status_id === AgentStatus.active || initialValues.status_id === AgentStatus.dismissed}
          helperText={errors.agent_account_nr}
          error={Boolean(errors.agent_account_nr) || false}
          fullWidth
        />
      </Grid>
      <Grid item>
        <FormControl
          fullWidth
          variant="standard"
          //// @ts-expect-error
          error={Boolean(errors.manager_id) || false}
          disabled={initialValues.status_id === AgentStatus.dismissed}
        >
          <InputLabel>Teamleiter</InputLabel>
          {/*{/* @ts-expect-error */}

          <Select
            name="manager_id"
            value={values.manager_id || ''}
            onChange={(e) => {
              handleChange(e);
              handleFormChange(values);
            }}
            variant="standard"
          >
            {getManager?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id.toString()}>
                  {item.surname}
                </MenuItem>
              );
            })}
          </Select>
          {/*{/* @ts-expect-error */}
          <FormHelperText>{errors.manager_id}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default AgentFormMainInfo;
