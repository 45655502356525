import React, { useContext, useState, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { DialogTitle, CircularProgress } from '@material-ui/core';
import { useFormikContext, Formik } from 'formik';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CachedIcon from '@material-ui/icons/Cached';
import DebounceTextField from '../../../components/DebounceTextfield';
import { AgentInputType } from '../../../types/AgentInputType.types';
import ChangeCorpEmailValid from '../../../types/validSchemas/ChangeCorpEmail.valid';
import DataContext from '../../../context/DataProvider/DataContext';
import changeCorpEmail from '../../../Apollo/mutations/changeCorpEmail';
import replaceUmlaute from '../../../services/replaceUmlaute';

function addEmailSuffix(value: string, suffix: string) {
  const newValue = value.replace(suffix, '');
  return `${newValue}@${suffix}`;
}
function removeEmailSuffix(value: string, suffix: string) {
  const newValue = value.replace(`@${suffix}`, '');
  return `${newValue}`;
}

function AgentFormChangeCorpEmail(): JSX.Element {
  const { values: mainValues, setFieldValue: mainSetFieldValue, dirty: mainDirty } = useFormikContext<AgentInputType>();
  const { agents, brachOffices } = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const [changeCorpMail, { data, loading }] = useMutation(changeCorpEmail);

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const emailSuffix: string = useMemo(
    () => brachOffices.filter((item) => item.id === mainValues.branch_office_id)[0]?.email_postfix,
    [brachOffices, mainValues],
  );

  return (
    <>
      <Tooltip title={mainDirty ? 'Änderungen speichern' : 'Email ändern'}>
        <span>
          <IconButton
            data-testid="email-change-btn"
            onClick={() => {
              handleOpen();
            }}
            disabled={mainDirty}
          >
            <CachedIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Formik
        enableReinitialize
        initialValues={{ email_corp: mainValues.email_corp }}
        onSubmit={(values) => {
          changeCorpMail({ variables: { newCorpMail: values.email_corp, agentId: mainValues.agent_id } }).then(
            (result) => {
              if (result) {
                mainSetFieldValue('email_corp', values.email_corp);
              }
            },
          );
        }}
        validationSchema={ChangeCorpEmailValid(agents, mainValues.email_corp)}
      >
        {({ values, handleBlur, errors, isValid, dirty, setFieldValue, submitForm, resetForm }) => (
          <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
            <DialogTitle>Geschäfts Email ändern</DialogTitle>
            <DialogContent>
              <DebounceTextField
                type="input"
                label="Name.Vorname"
                name="email_corp"
                aria-label="change_email_corp"
                value={removeEmailSuffix(values.email_corp || '', emailSuffix)}
                onChange={(e) => {
                  setFieldValue('email_corp', addEmailSuffix(replaceUmlaute(e.target.value), emailSuffix));
                }}
                onBlur={handleBlur}
                variant="standard"
                helperText={errors.email_corp && errors.email_corp}
                error={Boolean(errors.email_corp) || false}
                fullWidth
                InputProps={{
                  endAdornment: <Box width="80%">{`@${emailSuffix}`}</Box>,
                }}
              />
            </DialogContent>
            <DialogActions disableSpacing={false}>
              <Button
                autoFocus
                onClick={() => {
                  handleClose();
                  if (dirty && !data) {
                    resetForm();
                  }
                }}
                variant="outlined"
                disabled={loading}
              >
                Abbrechen
              </Button>
              <Button
                data-testid="savebtn_change_email"
                onClick={() => {
                  submitForm();
                  if (data) {
                    handleClose();
                  }
                }}
                variant="outlined"
                disabled={!isValid || !dirty}
              >
                Speichern {loading && <CircularProgress size="20px" />}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </>
  );
}

export default AgentFormChangeCorpEmail;
