import React, { useState, useEffect, useContext } from 'react';
import { Grid, Box, Button, IconButton, Tooltip } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { Receipt } from 'global-intern-types';
import createReceiptQuery from '../../../../Apollo/mutations/createReceipt';
import ReceiptEditAgent from './ReceiptEditAgent';
import ReceiptEditBookingAccount from './ReceiptEditBookingAccount';
import ReceiptEditDescription from './ReceiptEditDescription';
import ReceiptEditMonth from './ReceiptEditMonth';
import ReceiptEditPrice from './ReceiptEditPrice';
import ReceiptEditTax from './ReceiptEditTax';
import ReceiptEditType from './ReceiptEditType';
import ReceiptEditYear from './ReceiptEditYear';
import AllReceiptsTable from '../../AllReceiptsPage/components/AllReceiptsTable';
import DataContext from '../../../../context/DataProvider/DataContext';
import { Agent, AgentStatus } from '../../../../types/Agent.types';
import { BookingAccount } from '../../../../types/BookingAccount.types';
import { Tax } from '../../../../types/Tax.types';
import UserContext from '../../../../context/UserProvider/UserContext';
import ReceiptInput from '../../../../types/ReceiptInput.types';
import getClosingDataPayrollPeriod from '../../../../Apollo/queries/getClosingDataPayrollPeriod';

const useStyles = makeStyles(() => ({
  agentEdit: {
    background: '#ffffff',
    padding: '20px',
    minHeight: '70vh',
  },
  headerGroup: {
    fontSize: '24px',
    paddingLeft: '13px',
    paddingTop: '20px',
    paddingBottom: '10px',
    color: '#828282',
  },
  submit: {
    width: '100%',
    margin: '5px',
    height: '60px',
    fontSize: '12px',
  },
}));

function ReceiptEdit() {
  const user = useContext(UserContext);
  const { agents, bookingAccount, tax, loaderFun, receipt: contextReceipts } = useContext(DataContext);
  const [createReceipt, { loading }] = useMutation<{ createReceipt: Receipt }>(createReceiptQuery);
  const { data: PeriodData } = useQuery(getClosingDataPayrollPeriod);
  const classes = useStyles();

  const receiptInit: ReceiptInput = {
    author_id: 0,
    agent_id: '',
    booking_account_id: 0,
    tax_id: 0,
    price: 0,
    description: '',
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    type: 'IncomeReceipt',
  };

  const [receipt, setReceipt] = useState(receiptInit);
  const [allAgents, setAllAgents] = useState<Agent[]>([]);
  const [taxList, setTaxList] = useState<Tax[]>([]);
  const [bookingAccountList, setBookingAccountList] = useState<BookingAccount[]>([]);

  useEffect(() => {
    if (tax.length === 0) {
      loaderFun.loadTax();
    }
    if (bookingAccount.length === 0) {
      loaderFun.loadBookingAccount();
    }
  }, []);

  useEffect(() => {
    setReceipt({ ...receipt, author_id: user.id });
  }, [user]);

  useEffect(() => {
    const closingDatePayrollPeriod = new Date(PeriodData?.getClosingDataPayrollPeriod);
    const closingPeriodDateFirstDay = new Date(
      closingDatePayrollPeriod.getFullYear(),
      closingDatePayrollPeriod.getMonth(),
      1,
    );

    setAllAgents(
      agents.filter((agent: Agent) => {
        const dismissedDate = agent.dismissed_at ? new Date(agent.dismissed_at) : null;
        const dismissedDateMonth = dismissedDate?.getMonth();
        const dismissedDateYear = dismissedDate?.getFullYear();
        const dismissedDateFirstDay =
          dismissedDateYear && dismissedDateMonth ? new Date(dismissedDateYear, dismissedDateMonth, 1) : null;

        const date = new Date();
        const dateLastMonthFirstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);

        if (
          agent.status.id === AgentStatus.active ||
          (dismissedDateFirstDay === dateLastMonthFirstDay && closingPeriodDateFirstDay === dateLastMonthFirstDay)
        ) {
          return agent;
        }
        return null;
      }),
    );
  }, [agents]);

  useEffect(() => {
    setBookingAccountList(bookingAccount);
  }, [bookingAccount]);

  useEffect(() => {
    setTaxList(tax);
  }, [tax]);

  const changeReceipt = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const value = event.target.value as string;
    if (event.target.name === 'price') {
      setReceipt({
        ...receipt,
        [event.target.name]: parseFloat(value),
      });
    } else if (event.target.name) {
      setReceipt({
        ...receipt,
        [event.target.name]: event.target.value,
      });
    } else {
      setReceipt({
        ...receipt,
      });
    }
  };

  // @ts-ignore
  const changeReceiptAgent = (event: React.SyntheticEvent, value) => {
    if (value != null) {
      setReceipt({
        ...receipt,
        agent_id: value.id,
      });
    } else {
      setReceipt({
        ...receipt,
        agent_id: '',
      });
    }
  };

  return (
    <Box className={classes.agentEdit}>
      <Grid container spacing={2}>
        <Grid item container xs={4} direction="row" alignItems="flex-end">
          <ReceiptEditAgent receipt={receipt} allAgents={allAgents} changeReceiptAgent={changeReceiptAgent} />
          <ReceiptEditType receipt={receipt} changeReceipt={changeReceipt} />
          <ReceiptEditBookingAccount
            receipt={receipt}
            changeReceipt={changeReceipt}
            bookingAccountList={bookingAccountList}
          />
          <ReceiptEditPrice receipt={receipt} changeReceipt={changeReceipt} />
          <ReceiptEditDescription receipt={receipt} changeReceipt={changeReceipt} />
          <ReceiptEditTax receipt={receipt} taxList={taxList} changeReceipt={changeReceipt} />
          <ReceiptEditMonth receipt={receipt} changeReceipt={changeReceipt} />
          <ReceiptEditYear receipt={receipt} changeReceipt={changeReceipt} />
          <Box ml={1}>
            <Button
              onClick={() => {
                createReceipt({ variables: { input: receipt } }).then((res) => {
                  if (res?.data?.createReceipt) {
                    const updatedReceipts = [...contextReceipts, res.data.createReceipt];
                    loaderFun.changeReceipts(updatedReceipts);
                  }
                });
              }}
              disabled={loading}
              variant="contained"
            >
              Beleg erstellen
            </Button>
          </Box>
          <Tooltip title="Zurücksetzten">
            <IconButton
              onClick={() => {
                setReceipt({
                  ...receipt,
                  price: 0,
                  description: '',
                });
              }}
            >
              <RotateLeftIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={8}>
          <AllReceiptsTable agentId={receipt.agent_id} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ReceiptEdit;
