import React, { useState, useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';

import { TextField, TextFieldProps } from '@material-ui/core';
import { useDebouncedCallback } from 'use-debounce';
import { AgentInputType } from '../../types/AgentInputType.types';
import Agentv2 from '../../types/Agentv2.types';
import handleFormChange from '../../pages/AgentEditPage/services/handleFormChange';

const DebounceTextField = (props: TextFieldProps) => {
  const [innerValue, setInnerValue] = useState('');
  const { values } = useFormikContext<AgentInputType | Agentv2>();

  useEffect(() => {
    if (props.value) {
      setInnerValue(props.value as string);
    } else {
      setInnerValue('');
    }
  }, [props.value]);

  const debouncedHandleOnChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event);
    }
  }, 200);

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    const newValue = event.currentTarget.value;

    if (props.name) {
      handleFormChange(values);
    }

    setInnerValue(newValue);
    debouncedHandleOnChange(event);
  }, []);

  return <TextField {...props} value={innerValue} onChange={handleOnChange} />;
};

export default DebounceTextField;
