import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

const TextEditor = ({ value, onValueChange }: Props) => {
  return (
    <TextField
      value={value || ''}
      onChange={(event) => onValueChange(event.target.value)}
      error={!value}
      helperText={value ? '' : 'Incorrect entry'}
    />
  );
};

export default TextEditor;
