import React, { useContext, useEffect } from 'react';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import DataContext from '../../../../../context/DataProvider/DataContext';

interface Props {
  value: number | undefined | string;
  onValueChange: (value: number | unknown) => void;
}

function TaxEditor({ value, onValueChange }: Props): JSX.Element {
  const { tax, loaderFun } = useContext(DataContext);

  useEffect(() => {
    loaderFun.loadTax();
  }, []);

  return (
    <FormControl fullWidth error>
      <Select
        input={<Input />}
        value={value}
        onChange={(event) => onValueChange(event.target.value)}
        style={{ width: '100%' }}
        error={!value}
      >
        {tax.map((taxItem) => (
          <MenuItem key={taxItem.id} value={taxItem.code}>
            {taxItem.percent}% : {taxItem.code}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{value ? '' : 'Incorrect entry'}</FormHelperText>
    </FormControl>
  );
}
export default TaxEditor;
