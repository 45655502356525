import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { useFormikContext } from 'formik';
import { AgentInputType } from '../../../../types/AgentInputType.types';
import Agentv2 from '../../../../types/Agentv2.types';
import getServicesByAgentID from '../../../../Apollo/queries/getServicesByAgentID';
import Table from '../../../../components/Table';
import columns from './components/columnDefenition';
import normalizeServicesById from './services/normalizeServicesById';
import getAllServicesQuery from '../../../../Apollo/queries/getAllServicesQuery';
import { AgentStatus } from '../../../../types/Agent.types';
import commitChanges from './commitChanges';
import removeAgentServiceQuery from '../../../../Apollo/mutations/removeAgentServiceQuery';
import DateEditor from './components/DateEditor';
import NumberEditor from './components/NumberEditor';
import TextEditor from './components/TextEditor';
import updateAgentServiceQuery from '../../../../Apollo/mutations/updateAgentServiceQuery';
import AlertMessage from '../../../../components/AlertMessage/AlertMessage';
import AgentServiceInit from '../../../../types/initValues/AgentService.init';
import { useLoadingContext } from '../../../../context/LoadingProvider/LoadingContext';

const dateColumns = ['start_date', 'end_date'];

function DateTypeProvider() {
  return <DataTypeProvider editorComponent={DateEditor} for={dateColumns} />;
}

const numberColumns = ['agent_service_price'];

function NuberTypeProvider() {
  return <DataTypeProvider editorComponent={NumberEditor} for={numberColumns} />;
}

const textColumns = ['agent_service_description'];

function TextTypeProvider() {
  return <DataTypeProvider editorComponent={TextEditor} for={textColumns} />;
}

const Provider = [DateTypeProvider, NuberTypeProvider, TextTypeProvider];

function AgentServicesTable() {
  const { values } = useFormikContext<AgentInputType | Agentv2>();
  const { id } = values;
  const { data, refetch, loading: loadingServiceByAgentID } = useQuery(getServicesByAgentID, { variables: { id } });
  const { data: allServices, loading: laodingAllServices } = useQuery(getAllServicesQuery);
  const [
    deleteAgentService,
    { data: dataDeleteAgentService, error: errorDelteAgentService, loading: loadingRemoveAgentService },
  ] = useMutation(removeAgentServiceQuery);
  const [
    updateAgentService,
    { data: dataUpdateAgentService, error: errorUpdateAgentService, loading: loadingUpdateAgentService },
  ] = useMutation(updateAgentServiceQuery);
  const { setLoading } = useLoadingContext();
  const editingStateColumnExtensions = [
    { columnName: 'id', editingEnabled: false },
    { columnName: 'service_default_price', editingEnabled: false },
    { columnName: 'service_title', editingEnabled: false },
    { columnName: 'service_type', editingEnabled: false },
    { columnName: 'service_tax', editingEnabled: false },
  ];

  setLoading(laodingAllServices || loadingServiceByAgentID || loadingRemoveAgentService || loadingUpdateAgentService);

  useEffect(() => {
    refetch();
  }, []);

  const row = data ? normalizeServicesById(data.getServicesByAgentID) : [AgentServiceInit];

  if (data && allServices) {
    return (
      <>
        <Table
          row={row}
          columns={columns}
          onCommitChanges={({ changed, deleted }) =>
            commitChanges({ changed, deleted, rows: row, refetch, updateAgentService, deleteAgentService })
          }
          columnExtensions={editingStateColumnExtensions}
          dataTypeProvider={Provider}
          editable={values.status_id !== AgentStatus.dismissed}
          showAddCommand
          showEditCommand
          showDeleteCommand
          defaultHiddenColumnNames={['agent_service_agent_id']}
          forServicesById={{
            tableName: 'AgentServiceTable',
            services: allServices.getAllServices,
            agent_id: id,
            refresh: refetch,
          }}
        />
        <AlertMessage
          typeMessage={(errorDelteAgentService || errorUpdateAgentService) && 'error'}
          alertMessage={(errorDelteAgentService || errorUpdateAgentService) && 'Es ist ein Fehler aufgetreten'}
          isAlertMessage={!!errorDelteAgentService || !!errorUpdateAgentService}
        />
        <AlertMessage
          typeMessage={
            (dataDeleteAgentService && !errorDelteAgentService && 'success') ||
            (dataUpdateAgentService && !errorUpdateAgentService && 'success')
          }
          alertMessage={
            (dataDeleteAgentService && !errorDelteAgentService && 'Leistung wurde gelöscht') ||
            (dataUpdateAgentService && !errorUpdateAgentService && 'Leistung erfolgreich geändert')
          }
          isAlertMessage={
            (!!dataDeleteAgentService && !errorDelteAgentService) ||
            (!!dataUpdateAgentService && !errorUpdateAgentService)
          }
        />
      </>
    );
  }
  return <>loading...</>;
}

export default AgentServicesTable;
