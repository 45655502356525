import React, { useEffect, useState, useCallback } from 'react';
import { Box, CardActions, IconButton, Modal, CardHeader } from '@material-ui/core';
import axios from 'axios';
import { pdfjs, Document, Page } from 'react-pdf';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfPreview = ({
  open,
  handleClose,
  pdfPath,
  filename,
}: {
  open: boolean;
  handleClose: () => void;
  pdfPath: string | null;
  filename?: string | null;
}) => {
  const [pdfFile, setPdfFile] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    if (pdfPath) {
      const fetchPdf = async () => {
        const response = await axios
          .get(pdfPath, {
            responseType: 'blob',
          })
          .catch((error) => {
            console.error('Error fetching pdf', error);
            setPdfFile(null);
            handleClose();
          });
        setPdfFile(response?.data);
      };

      fetchPdf();
    }
  }, [pdfPath, open]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const handlePreviousPage = useCallback(() => {
    setPageNumber(pageNumber - 1);
  }, [pageNumber]);

  const handleNextPage = useCallback(() => {
    setPageNumber(pageNumber + 1);
  }, [pageNumber]);

  const handleZoomIn = useCallback(() => {
    setZoom(zoom + 0.1);
  }, [zoom]);

  const handleZoomOut = useCallback(() => {
    setZoom(zoom - 0.1);
  }, [zoom]);

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason == 'backdropClick') {
          handleClose();
        }
      }}
    >
      <>
        {pdfFile === null && <div>Loading...</div>}
        {pdfFile && (
          <Card sx={{ marginTop: '5%', marginLeft: '10%', marginRight: '10%', marginBottom: '10%' }}>
            <CardHeader
              title="Vorschau"
              action={
                <IconButton aria-label="settings" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              }
              subheader={filename ?? ''}
            />
            <CardContent sx={{ height: 'calc(80vh)', overflow: 'auto' }}>
              <Box display="flex" sx={{ justifyContent: 'center', overflow: 'scroll', minHeight: '200px' }}>
                <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} scale={zoom} />
                </Document>
              </Box>
            </CardContent>
            <CardActions>
              <Box display="flex" justifyContent="center" width="100%" justifyItems="center">
                <IconButton onClick={handlePreviousPage} disabled={pageNumber === 1}>
                  <ArrowBackIosIcon />
                </IconButton>
                <Box display="flex" justifyContent="center" justifyItems="center">
                  Seite {pageNumber} von {numPages}
                </Box>
                <IconButton onClick={handleNextPage} disabled={pageNumber === numPages}>
                  <ArrowForwardIosIcon />
                </IconButton>

                <IconButton onClick={handleZoomIn}>
                  <ZoomInIcon />
                </IconButton>
                <IconButton onClick={handleZoomOut}>
                  <ZoomOutIcon />
                </IconButton>
              </Box>
            </CardActions>
          </Card>
        )}
      </>
    </Modal>
  );
};

export default PdfPreview;
