import React, { useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DataContext from '../../context/DataProvider/DataContext';
import StartSoon from '../../components/StartSoonAgent';
import BirthdaysOfMonth from '../../components/BirthdaysOfMonth';

function DashboardPage() {
  const context = useContext(DataContext);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        style={{ backgroundColor: '#F2F5F8' }}
      >
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="sub_title"
          label="Active Berater"
          name="sub_title"
          autoComplete="sub_title"
          value={context.agents.length || 0}
        />
        <Grid item xs>
          <StartSoon />
        </Grid>
        <Grid item xs>
          <BirthdaysOfMonth />
        </Grid>
      </Grid>
    </>
  );
}
export default DashboardPage;
