import React from 'react';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { MONTHS } from '../../../../../services/config/CONST';

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}
function StartMonthEditor({ value, onValueChange }: Props): JSX.Element {
  return (
    <FormControl fullWidth error>
      <Select
        input={<Input />}
        value={value}
        onChange={(event) => onValueChange(event.target.value as string)}
        style={{ width: '100%' }}
        error={!value}
      >
        {MONTHS.map((monthItem) => (
          <MenuItem key={monthItem.id} value={monthItem.title}>
            {monthItem.title}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{value ? '' : 'Incorrect entry'}</FormHelperText>
    </FormControl>
  );
}

export default StartMonthEditor;

