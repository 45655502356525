import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import { UploadFiles } from '../../../../components/Files';
import { default as getAllAgentFilesQuery } from '../../../../Apollo/queries/getAllAgentFiles';
import DataContext from '../../../../context/DataProvider/DataContext';
import Table from '../../../../components/Table';
import PdfPreview from '../../../../components/Files/pdfViewer/PdfPreview';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import deleteAgentFile from '../../../../Apollo/mutations/deleteAgentFile';
import FileOpenIcon from '@mui/icons-material/FileOpen';
type FileT = { id: number; filename: string; name: string; size: number; path: string };

const AgentFilesTable = () => {
  const [open, setOpen] = useState(false);
  const [fileID, setFileID] = useState<number | null>(null);
  const [pdfPath, setPdfPath] = useState<string | null>(null);
  const [pdfName, setPdfName] = useState<string | null>(null);
  const { agents } = useContext(DataContext);
  const [getAllAgentFiles, { data, refetch, loading }] = useLazyQuery<{
    getAllAgentFiles: [FileT];
  }>(getAllAgentFilesQuery);

  const [deleteFile, { error, loading: loadingDelete }] = useMutation(deleteAgentFile);

  const { number: AgentID } = useParams<{ number?: string }>();
  const agent = agents.find((agent) => agent.agent_id === parseInt(AgentID ?? '', 10));

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!data && agent && agent.id) {
      getAllAgentFiles({ variables: { agent_id: agent?.id } });
    }
  }, [data, agent?.id]);

  function EditCellForComand(props: TableEditColumn.CellProps) {
    const row = props.row as FileT;
    return (
      <>
        <TableEditColumn.Cell {...props}>
          {loadingDelete && fileID === row.id && <CircularProgress />}
          {loadingDelete && fileID !== row.id && (
            <>
              <IconButton>
                <FileOpenIcon
                  fontSize="medium"
                  sx={{ cursor: 'pointer', color: '#0000008a' }}
                  onClick={() => {
                    setPdfPath(`${row.path}/${row.filename}`);
                    setPdfName(row.name);
                    setOpen(true);
                  }}
                />
              </IconButton>
              {props.children}
            </>
          )}
          {!loadingDelete && (
            <>
              <IconButton>
                <FileOpenIcon
                  fontSize="medium"
                  sx={{ cursor: 'pointer', color: '#0000008a' }}
                  onClick={() => {
                    setPdfPath(`${props.row.path}/${props.row.filename}`);
                    setPdfName(props.row.name);
                    setOpen(true);
                  }}
                />
              </IconButton>
              {props.children}
            </>
          )}
        </TableEditColumn.Cell>
      </>
    );
  }

  const columns = [
    { name: 'name', title: 'Dateiname' },
    { name: 'size', title: 'Größe' },
  ];

  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
          <Box display="flex" flexDirection="column" justifyContent="center" sx={{ width: '50%' }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        </Box>
      )}
      {!loading && (
        <>
          <PdfPreview open={open} handleClose={handleClose} pdfPath={pdfPath} filename={pdfName} />
          <UploadFiles agent_id={agent?.id} refetch={refetch} />
          <Table
            EditCellForComand={EditCellForComand}
            onCommitChanges={({ deleted }) => {
              if (deleted && deleted[0]) {
                setFileID(parseInt(deleted[0].toString(), 10));
                deleteFile({ variables: { file_id: deleted[0], agent_id: agent?.id } })
                  .then(() => {
                    refetch();
                  })
                  .catch((e) => {
                    console.error('deleteFile:error', e);
                  });
              }
            }}
            row={data?.getAllAgentFiles ?? []}
            columns={columns}
            editable
            showDeleteCommand
          />
        </>
      )}
    </>
  );
};

export default AgentFilesTable;
