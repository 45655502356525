import React from 'react';
import AgentEditPage from './modules/pages/AgentEditPage/AgentEditPage';
import FinancePage from './modules/pages/Finace/AllReceiptsPage';
import AllAgentsPage from './modules/pages/AgentTable';
import AllInteressentenPage from './modules/pages/Leads/LeadsTable';
import DashboardPage from './modules/pages/DashboardPage';
import ReceiptPage from './modules/pages/Finace/ReceiptEditPage';
import OptionsPage from './modules/pages/Options/BookingAccount/OptionsPage';
import ServicesPage from './modules/pages/Options/ServicesPage';
import RankPage from './modules/pages/Options/RankPage/RankPage';
import LevelsPage from './modules/pages/Options/LevelsPage/LevelsPage';
import UserPage from './modules/pages/Options/UserPage/UserPage';
import BranchofficePage from './modules/pages/Options/BrachofficePage/BranchofficePage';
import TaxPage from './modules/pages/Options/TaxPage';
import CounrysPage from './modules/pages/Options/CountrysPage/index';
import ContractTypsPage from './modules/pages/Options/ContractTypsPage/ContractTypsPage';
import MontlyAccountingPage from './modules/pages/Finace/MontlyAccountingPage/MountlyAccountingPage';
import AdminPage from './modules/pages/Options/AdminPage/index';
import Map from './modules/pages/Map';
import ImportShopReceipts from './modules/pages/Finace/ImportShopReceipts';
import InteressentPage from './modules/pages/Leads/EditPage';
import ServicesReportPage from './modules/pages/Reports/ServicesReportPage/index';

export enum PagePathname {
  dashboard = '/',
  alleBerater = '/all-agents',
  alleBelege = '/finance',
  monatlicherAbschluss = '/montlyAccounting',
  buchungskonten = '/bookingAccount',
  ränge = '/ranks',
  levels = '/levels',
  benutzer = '/users',
  länder = '/coutrys',
  vertragsarten = '/contractTyps',
  marken = '/branchOffice',
  leistungen = '/services',
  steuern = '/tax',
  beraterErstellen = '/create-agent',
  beraterBearbeiten = '/agent/:number',
  belegeErstellen = '/receipt-create',
  belege = '/receipt-edit/:number',
  adminPanel = '/admin-panel',
  karte = '/map',
  shopifyBelege = '/import-shop-receipts',
  logout = '/logout',
  servicesReport = '/servicesReport',
}

export type Page = {
  title: string;
  path: string;
  render: JSX.Element;
  showInNavbar: boolean;
};

let pages: Page[] = [
  {
    title: 'Dashboard',
    path: PagePathname.dashboard,
    render: <DashboardPage />,
    showInNavbar: true,
  },
  {
    title: 'Berater',
    path: PagePathname.alleBerater,
    render: <AllAgentsPage />,
    showInNavbar: true,
  },

  {
    title: 'Belege',
    path: PagePathname.alleBelege,
    render: <FinancePage />,
    showInNavbar: true,
  },
  {
    title: 'Monatlicher Abschluss',
    path: PagePathname.monatlicherAbschluss,
    render: <MontlyAccountingPage />,
    showInNavbar: true,
  },
  {
    title: 'Buchungskonten',
    path: PagePathname.buchungskonten,
    render: <OptionsPage />,
    showInNavbar: true,
  },
  {
    title: 'Ränge',
    path: PagePathname.ränge,
    render: <RankPage />,
    showInNavbar: true,
  },
  {
    title: 'Levels',
    path: PagePathname.levels,
    render: <LevelsPage />,
    showInNavbar: true,
  },
  {
    title: 'Benutzer',
    path: PagePathname.benutzer,
    render: <UserPage />,
    showInNavbar: true,
  },
  {
    title: 'Länder',
    path: PagePathname.länder,
    render: <CounrysPage />,
    showInNavbar: true,
  },

  {
    title: 'Vertragsarten',
    path: PagePathname.vertragsarten,
    render: <ContractTypsPage />,
    showInNavbar: true,
  },
  {
    title: 'Marken',
    path: PagePathname.marken,
    render: <BranchofficePage />,
    showInNavbar: true,
  },
  {
    title: 'Leistungen',
    path: PagePathname.leistungen,
    render: <ServicesPage />,
    showInNavbar: true,
  },
  {
    title: 'Leistungen Bericht',
    path: PagePathname.servicesReport,
    render: <ServicesReportPage />,
    showInNavbar: true,
  },
  {
    title: 'Steuern',
    path: PagePathname.steuern,
    render: <TaxPage />,
    showInNavbar: true,
  },

  {
    title: 'Berater erstellen',
    path: PagePathname.beraterErstellen,
    render: <AgentEditPage />,
    showInNavbar: true,
  },
  {
    title: 'Berater bearbeiten',
    path: PagePathname.beraterBearbeiten,
    render: <AgentEditPage />,
    showInNavbar: true,
  },
  {
    title: 'Belege erstellen',
    path: PagePathname.belegeErstellen,
    render: <ReceiptPage />,
    showInNavbar: true,
  },
  {
    title: 'Belege',
    path: PagePathname.belege,
    render: <ReceiptPage />,
    showInNavbar: true,
  },
  {
    title: 'Admin panel',
    path: PagePathname.adminPanel,
    render: <AdminPage />,
    showInNavbar: true,
  },
  {
    title: 'Karte',
    path: PagePathname.karte,
    render: <Map />,
    showInNavbar: true,
  },
  {
    title: 'Shopify Belege',
    path: PagePathname.shopifyBelege,
    render: <ImportShopReceipts />,
    showInNavbar: true,
  },
];

if (process.env.NODE_ENV === 'development') {
  pages = pages.concat([
    {
      title: 'Interessenten',
      path: '/all-interessents',
      render: <AllInteressentenPage />,
      showInNavbar: false,
    },
    {
      title: 'Interessenten anlegen',
      path: '/create-interessent',
      render: <InteressentPage />,
      showInNavbar: false,
    },
    {
      title: 'Interessenten bearbeiten',
      path: '/interessent/:number',
      render: <InteressentPage />,
      showInNavbar: false,
    },
    {
      title: 'Interessenten anlegen',
      path: '/create-interessent',
      render: <InteressentPage />,
      showInNavbar: true,
    },
    {
      title: 'Interessenten bearbeiten',
      path: '/interessent/:number',
      render: <InteressentPage />,
      showInNavbar: true,
    },
  ]);
}

export default pages;
