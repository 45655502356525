import React, { useEffect, useContext } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { useMutation } from '@apollo/client';
import Table from '../../../../components/Table/Table';
import NumberEditor from './components/NumberEditor';
import TextEditor from './components/TextEditor';
import columns from './columnDefinition';
import DataContext from '../../../../context/DataProvider/DataContext';
import commitChanges from './commitChanges';
import createBookingAccountQuery from '../../../../Apollo/mutations/createBookingAccount';
import updateBookingAccountQuery from '../../../../Apollo/mutations/updateBookingAccount';

const numberColumns = ['booking_account_nr'];
function NumberTypeProvider() {
  return <DataTypeProvider editorComponent={NumberEditor} for={numberColumns} />;
}

const textColumns = ['title'];
function TextTypeProvider() {
  return <DataTypeProvider editorComponent={TextEditor} for={textColumns} />;
}

const Provider = [NumberTypeProvider, TextTypeProvider];

function AllBookingAccountsTable() {
  const { bookingAccount, loaderFun } = useContext(DataContext);
  const [createBookingAccount] = useMutation(createBookingAccountQuery);
  const [updateBookingAccount] = useMutation(updateBookingAccountQuery);

  useEffect(() => {
    loaderFun.loadBookingAccount();
  }, []);

  const editingStateColumnExtensions = [{ columnName: 'id', editingEnabled: false }];

  return (
    <Table
      row={bookingAccount}
      columns={columns}
      editable
      pageSize={10}
      defaultColumnWidths={columns.map((column) => ({ columnName: column.name, width: 'auto' }))}
      onCommitChanges={({ added, changed }) =>
        commitChanges({
          added,
          changed,
          rows: bookingAccount,
          loaderFun,
          createBookingAccount,
          updateBookingAccount,
        })
      }
      columnExtensions={editingStateColumnExtensions}
      dataTypeProvider={Provider}
      showEditCommand
      showAddCommand
    />
  );
}

export default AllBookingAccountsTable;
