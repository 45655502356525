import dateToServices from '../../../../services/dateToServices';

export default function ({ changed, deleted, rows, refetch, updateAgentService, deleteAgentService }) {
  let changedRows;

  if (changed) {
    const currentRows = [...rows];
    changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));

    let input = {};
    for (let i = 0; i < currentRows.length; i += 1) {
      if (currentRows[i] !== changedRows[i]) {
        if (changedRows[i].end_date || changedRows[i].start_date) {
          if (changedRows[i].end_date === '') {
            input = {
              agent_id: changedRows[i].agent_service_agent_id,
              service_id: changedRows[i].id,
              price: parseInt(changedRows[i].agent_service_price, 10),
              description: changedRows[i].agent_service_description,
              start_date: dateToServices(changedRows[i].service_type, changedRows[i].start_date),
            };
          } else {
            input = {
              agent_id: changedRows[i].agent_service_agent_id,
              service_id: changedRows[i].id,
              price: parseInt(changedRows[i].agent_service_price, 10),
              description: changedRows[i].agent_service_description,
              end_date: dateToServices(changedRows[i].service_type, changedRows[i].end_date),
              start_date: dateToServices(changedRows[i].service_type, changedRows[i].start_date),
            };
          }
        } else {
          input = {
            agent_id: changedRows[i].agent_service_agent_id,
            service_id: changedRows[i].id,
            price: parseInt(changedRows[i].agent_service_price, 10),
            description: changedRows[i].agent_service_description,
          };
        }
      }
    }
    if (changedRows) {
      updateAgentService({ variables: { variables: input } }).then((resultat) => {
        if (resultat.data) refetch();
      });
    }
  }
  if (deleted) {
    const deletedSet = new Set(deleted);
    changedRows = rows.filter((row) => !deletedSet.has(row.id));
    const setIter = deletedSet.values();
    const setValue = setIter.next().value;
    const id = parseInt(setValue, 10);
    if (id) {
      deleteAgentService({ variables: { id } }).then((result) => {
        if (result.data) refetch();
      });
    }
  }
}

