import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ReceiptInput from '../../../../types/ReceiptInput.types';

const useStyles = makeStyles((theme) => ({
  blockInput: {
    width: '100%',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formInput: {
    background: '#F4F7FA',
    color: '#828282',
    minWidth: 185,
  },
}));

interface Props {
  receipt: ReceiptInput;
  changeReceipt: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => void;
}

function ReceiptEditYear({ receipt, changeReceipt }: Props) {
  const classes = useStyles();
  const [receiptYear, setReceiptYear] = useState(0);

  useEffect(() => {
    setReceiptYear(receipt.year);
  }, [receipt]);

  const currentYear = new Date().getUTCFullYear();
  const currentMonth = new Date().getUTCMonth();

  return (
    <Box className={classes.blockInput}>
      <FormControl variant="filled" required className={classes.formControl} error={!receipt.year}>
        <InputLabel id="ReceiptEditYearLabel">Jahr</InputLabel>
        <Select
          labelId="ReceiptEditYearLabel"
          id="ReceiptEditYear"
          value={receiptYear || ''}
          onChange={changeReceipt}
          className={classes.formInput}
          name="year"
        >
          <MenuItem key={currentYear} value={currentYear}>
            {currentYear}
          </MenuItem>
          {currentMonth === 0 && (
            <MenuItem key={currentYear - 1} value={currentYear - 1}>
              {currentYear - 1}
            </MenuItem>
          )}
          <MenuItem key={currentYear + 1} value={currentYear + 1}>
            {currentYear + 1}
          </MenuItem>
        </Select>
        {!receipt.year && <FormHelperText>falsche Eingabe</FormHelperText>}
      </FormControl>
    </Box>
  );
}

export default ReceiptEditYear;
