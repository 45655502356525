import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

const useStyles = makeStyles((theme) => ({
  iconAndErrorMessageWrapper: { display: 'flex', alignItems: 'center' },
}));

function ErrorMessageLayout() {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h1" color="textSecondary">
        Error
      </Typography>
      <div className={classes.iconAndErrorMessageWrapper}>
        <ReportProblemIcon color="error" fontSize="large" />
        <Typography variant="h6" color="error">
          Opps! Something went wrong.
        </Typography>
      </div>
    </div>
  );
}
class ErrorBoundaries extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch = (error, errorMessage) => {
    this.setState({ hasError: error, errorMessage: errorMessage });
  };

  render() {
    if (this.state.hasError) {
      return <ErrorMessageLayout />;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundaries;
