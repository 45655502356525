import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useHistory } from 'react-router-dom';

interface CustomConfirmModalProps {
  open: boolean;
  onClose: (proceed: boolean) => void;
  link: string;
  closeModal: () => void;
}

/**
 * @description Eine Komponenten zur Darstellung eines Modalfensters, mit zwei Knöpfen.
 *  Beim Betätigen des Knopfes mit der Überschrift "Zurück zur Maske", kehrt der User zur AgentEditPage.
 *  Beim Betätigen des Knopfes mit der Überschrift "Trotzdem fortfahren", wird der User zum Login umgeleitet, localStorage wird gelehrt.
 * @param open boolean
 * @param onClose (proceed: boolean) => void
 * @param link string
 * @param closeModal () => void
 */

const ConfirmExitAppModal: React.FC<CustomConfirmModalProps> = ({ open, onClose, link, closeModal }) => {
  const history = useHistory();

  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Ungespeicherte Daten</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          In "Berater Erstellen" befinden sich noch ungespeicherte Daten. Wenn Sie die Anwendung verlassen, gehen diese
          Daten verloren.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            closeModal();
            history.push(link);
          }}
          color="primary"
        >
          Zurück zur Maske
        </Button>
        <Button onClick={() => onClose(true)} color="primary" autoFocus>
          Trotzdem fortfahren
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmExitAppModal;
