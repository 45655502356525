import React, { useState, useEffect } from 'react';
import { Box, Button, Tabs, Tab, Grid, Tooltip, Typography, Link } from '@material-ui/core';
import { Formik, FormikProps } from 'formik';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import TabPanel from './TabPanel';
import DebounceTextField from '../../../components/DebounceTextfield';
import InteressentServiceTable from './InteressentServicesTable/InteressentServiceTable';
import InteressentPersonalData from './components/InteressentPersonalData';
import InteressentMainInfo from './components/InteressentMainInfo';
import InteressentDeliveryAddress from './components/InteressentDeliveryAddress';
import IneressentContractStatus from './components/IneressentContractStatus';
import Interessent from '../../../types/Interessent.types';
import createInteresentQuery from '../../../Apollo/mutations/createInteressent';
import updateInteresentQuery from '../../../Apollo/mutations/updateIneressent';
import getAllInteressents from '../../../Apollo/queries/getAllInteressents';
import InteressentValid from '../../../types/validSchemas/Interessent.valid';
import getInteressentById from '../../../Apollo/queries/getInteressetnById';
import removeTypename from './services/removeTypename';
import { useLoadingContext } from '../../../context/LoadingProvider/LoadingContext';

const InteressentInitValue: Interessent = {
  id: null,
  name: '',
  surname: '',
  agent_id: null,
  phone: null,
  mobile: null,
  email_private: '',
  contract_type_id: null,
  address: null,
  zip: null,
  city: null,
  country_id: null,
  notes: null,
  branch_office_id: null,
};

function InteressentPage() {
  const [valueTab, setValueTab] = useState(0);
  const { number: InteressentID } = useParams<{ number?: string }>();
  const [getInteressentDataByID, { data: interessentData, loading: loadingInteressentDataByID }] =
    useLazyQuery(getInteressentById);
  const [createInteresent, { loading: loadingCreateInteressent }] = useMutation(createInteresentQuery);
  const [updateInteresent, { loading: loadingUpdateInteressent }] = useMutation(updateInteresentQuery);
  const { data, loading: loadingAllInteressent } = useQuery(getAllInteressents);
  const history = useHistory();
  const { setLoading } = useLoadingContext();

  const handleChangeValueTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValueTab(newValue);
  };

  let InteressentIDNumber: Number;

  setLoading(
    loadingInteressentDataByID || loadingCreateInteressent || loadingUpdateInteressent || loadingAllInteressent,
  );

  useEffect(() => {
    if (InteressentID) {
      InteressentIDNumber = parseInt(InteressentID, 10);
      const probe = { id: InteressentIDNumber };
      getInteressentDataByID({ variables: { variables: probe } });
    }
  }, [InteressentID]);

  return (
    <Formik
      key={interessentData?.getInteresentById}
      initialValues={interessentData?.getInteresentById ?? InteressentInitValue}
      onSubmit={(values, { setSubmitting }) => {
        if (InteressentID) {
          const formatetValues: any = values;
          let valueWithoutTypename = removeTypename(formatetValues);
          delete valueWithoutTypename.converting;

          updateInteresent({ variables: { variables: valueWithoutTypename } }).then((result) => {
            if (result.data.updateInteresent) {
              window.location.reload();
              // Todo: Erfolgsmeldung einbauen und loading einbauen
            }
          });
        } else {
          if (values) {
            const probe = values;
            // @ts-expect-error
            delete probe.agent_id;
            // @ts-expect-error
            delete probe.id;
            createInteresent({ variables: { variables: values } }).then((result) => {
              history.push(`/interessent/${result.data.createInteresent.id}`);
            });
          }
        }
      }}
      validationSchema={InteressentValid}
      validateOnMount
    >
      {(props: FormikProps<Interessent>) => {
        const { values, dirty, handleChange, handleBlur, handleSubmit, handleReset, isValid, isSubmitting } = props;

        return (
          <Box bgcolor="#fff" p={2}>
            {values.converting && (
              <>
                <Typography style={{ color: 'red', fontWeight: 'bold' }}>
                  DER INTERESSENT WURDE AM ZUM <Link href={`/agent/${values.agent_id}`}>BERATER</Link>{' '}
                  {values.converting.split('T')[0]} KONVERTIERT
                </Typography>
              </>
            )}
            <Tabs value={valueTab} onChange={handleChangeValueTab} indicatorColor="primary" textColor="primary">
              <Tab data-testid="interessent-tab" label="Interessent" />
              {values.agent_id ? (
                <Tab data-testid="services-tab" label="Leistungen" />
              ) : (
                <Tooltip title={values.agent_id ? '' : 'Berater Nummer wird benötigt'} style={{ cursor: 'pointer' }}>
                  <Box>
                    <Tab data-testid="services-tab" label="Leistungen" disabled={!values.agent_id} />
                  </Box>
                </Tooltip>
              )}
            </Tabs>
            <TabPanel value={valueTab} index={0}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={2}>
                  <InteressentPersonalData />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <InteressentMainInfo />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <InteressentDeliveryAddress />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <IneressentContractStatus />
                </Grid>
                <Grid item xs={12} md={12} lg={10}>
                  <DebounceTextField
                    label="Notizen"
                    name="notes"
                    value={values.notes || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    multiline
                    minRows={15}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end" spacing={3}>
                <Grid item>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Button
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={!isValid || !dirty}
                      variant="outlined"
                      color="secondary"
                    >
                      Speichern
                    </Button>
                  </Box>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      handleReset();
                    }}
                    disabled={!dirty || isSubmitting}
                    variant="outlined"
                    color="secondary"
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
              <InteressentServiceTable />
            </TabPanel>
          </Box>
        );
      }}
    </Formik>
  );
}

export default InteressentPage;
