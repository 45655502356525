import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ThemeOption from '../../../../services/themeOption';
import { CONTRACTTYPSLIST } from '../../../../services/config/CONST';

const useStyles = makeStyles((theme) => ({
  blockInput: {
    width: '100%',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formInput: {
    background: '#F4F7FA',
    color: '#828282',
    minWidth: 185,
  },
}));

function ReceiptEditType({ receipt, changeReceipt }) {
  const classes = useStyles();
  const [receiptType, setReceiptType] = useState(null);

  useEffect(() => {
    setReceiptType(receipt.type);
  }, [receipt]);

  const listItems = CONTRACTTYPSLIST.map((item) => (
    <MenuItem key={item.id} value={item.value}>
      {item.title}
    </MenuItem>
  ));

  return (
    <ThemeProvider theme={ThemeOption}>
      <Box className={classes.blockInput}>
        <FormControl variant="filled" required className={classes.formControl} error={receipt.type ? false : true}>
          <InputLabel id="ReceiptEditTypeLabel">Art</InputLabel>
          <Select
            labelId="ReceiptEditTypeLabel"
            id="ReceiptEditType"
            value={receiptType || ''}
            onChange={changeReceipt}
            className={classes.formInput}
            name="type"
          >
            {listItems}
          </Select>
          {!receipt.type && <FormHelperText>Incorrect entry</FormHelperText>}
        </FormControl>
      </Box>
    </ThemeProvider>
  );
}

export default ReceiptEditType;
