import React, { useCallback } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core/styles';
import Main from './Routes';
import CommunicationProvider from './modules/context/CommunicationProvider/CommunicationProvider';
import DataContextProvider from './modules/context/DataProvider/DataProvider';
import ThemeOption from './modules/services/themeOption';
import UserProvider from './modules/context/UserProvider/UserProvider';
import ErrorMessage from './modules/components/ErrorMessage/ErrorMessage';
import { LoadingProvider } from './modules/context/LoadingProvider/LoadingContext';
import Loading from './modules/components/LoadingDialog/Loading';

function App() {
  const [userToken, setUserToken] = React.useState<string>('');

  const setApolloClientToken = useCallback(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setUserToken(token);
    }
  }, []);

  return (
    <LoadingProvider>
      <ErrorMessage token={userToken}>
        <UserProvider>
          <DataContextProvider>
            <CommunicationProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={ThemeOption}>
                  <BrowserRouter>
                    <Loading />
                    <Main setUserToken={setUserToken} setApolloClientToken={setApolloClientToken} />
                  </BrowserRouter>
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </CommunicationProvider>
          </DataContextProvider>
        </UserProvider>
      </ErrorMessage>
    </LoadingProvider>
  );
}

export default App;
