import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ThemeOption from '../../../services/themeOption';

interface ServiceSelect {
  booking_account: string;
  tax: string;
  fixed_start_month: string | undefined | number | null;
  default_price: number;
  id: number;
  title: string;
  type: string;
}

type Props = {
  service: ServiceSelect;
  servicesList: ServiceSelect[];
  changeFunc: any;
};

const useStyles = makeStyles((theme) => ({
  blockInput: {
    width: '100%',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formInput: {
    background: '#F4F7FA',
    color: '#040404',
    minWidth: 185,
  },
}));

function ServicesSelect({ service, servicesList, changeFunc }: Props) {
  const classes = useStyles();

  const listItems = servicesList.map((item: ServiceSelect) => (
    <MenuItem key={item.id} value={item.id}>
      {item.title}
    </MenuItem>
  ));

  return (
    <ThemeProvider theme={ThemeOption}>
      <Box className={classes.blockInput}>
        <FormControl variant="filled" required className={classes.formControl}>
          <InputLabel id="ReceiptEditTaxLabel">Leistungen</InputLabel>
          <Select
            labelId="ServicesSelect"
            id="ServicesSelect"
            value={service.id || ''}
            onChange={changeFunc}
            className={classes.formInput}
            name="id"
          >
            {listItems}
          </Select>
        </FormControl>
      </Box>
    </ThemeProvider>
  );
}

export default ServicesSelect;
