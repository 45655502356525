import React from 'react';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Roles } from '../../../../../types/Roles.types';

const rolesList: Roles[] = [
  { id: 1, role: 'ADMIN' },
  { id: 2, role: 'SUPERADMIN' },
  { id: 3, role: 'REDACTOR' },
  { id: 4, role: 'VIEWER' },
];

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

function RolesEditor({ value, onValueChange }: Props): JSX.Element {
  return (
    <Select
      input={<Input />}
      value={value || ''}
      onChange={(event) => onValueChange(event.target.value as string)}
      style={{ width: '100%' }}
    >
      {rolesList.map((rolesItem) => (
        <MenuItem key={rolesItem.id} value={rolesItem.role}>
          {rolesItem.role}
        </MenuItem>
      ))}
    </Select>
  );
}

export default RolesEditor;

