import React from 'react';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { ServicesTypes } from '../../../../../types/ServicesTypes.types';

const typesList: ServicesTypes[] = [
  { id: 1, title: 'MonthlyService' },
  { id: 2, title: 'SingleService' },
  { id: 3, title: 'YearlyService' },
];

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

function TypeEditor({ value, onValueChange }: Props): JSX.Element {
  return (
    <FormControl fullWidth error>
      <Select
        input={<Input />}
        value={value || ''}
        onChange={(event) => onValueChange(event.target.value as string)}
        style={{ width: '100%' }}
        error={!value}
      >
        {typesList.map((typeItem) => (
          <MenuItem key={typeItem.id} value={typeItem.title}>
            {typeItem.title}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{value ? '' : 'Incorrect entry'}</FormHelperText>
    </FormControl>
  );
}

export default TypeEditor;

