import { gql } from '@apollo/client';

const getAllAgentsByServiceID = gql`
  query ($service_id: Int!) {
    getAllAgentsByServiceID(service_id: $service_id) {
      name
      surname
      agent_id
      email_corp
      city
      address
      zip
      mobile
    }
  }
`;

export default getAllAgentsByServiceID;
