import React, { useContext, useState } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import DataContext from '../../../../../context/DataProvider/DataContext';
import columns from './columnDefinition';
import BooleanFormatter from './services/booleanFormatter';
import BooleanEditor from './components/BooleanEditor';
import TextEditor from './components/TextEditor';
import NumberEditor from './components/NumberEditor';
import EmailEditor from './components/EmailEditor';
import Table from '../../../../../components/Table/Table';
import OptionButton from './components/OptionButton';

const textColumns = ['title'];

function TextTypeProvider() {
  return <DataTypeProvider editorComponent={TextEditor} for={textColumns} />;
}

const emailColums = ['email_postfix'];

function EmailTypeProvider() {
  return <DataTypeProvider editorComponent={EmailEditor} for={emailColums} />;
}

const booleanColumns = ['is_default'];

function BooleanTypeProvider() {
  return (
    <DataTypeProvider formatterComponent={BooleanFormatter} editorComponent={BooleanEditor} for={booleanColumns} />
  );
}

const numberColumns = ['min_agent_id', 'max_agent_id'];

function NumberTypeProvider() {
  return <DataTypeProvider editorComponent={NumberEditor} for={numberColumns} />;
}

const optionsColumn = ['option'];

function OptionTypeProvider() {
  return <DataTypeProvider editorComponent={OptionButton} for={optionsColumn} />;
}

const Provider = [BooleanTypeProvider, TextTypeProvider, NumberTypeProvider, EmailTypeProvider, OptionTypeProvider];

function AllBrachofficeTable() {
  const { brachOffices } = useContext(DataContext);
  const [defaultHiddenColumn] = useState(['domain', 'show_in_export', 'logo', 'title_for_worker_passport']);

  const editingStateColumnExtensions = [
    { columnName: 'id', editingEnabled: false },
    { columnName: 'option', editingEnabled: false },
  ];
  return (
    <>
      <Table
        tableName="BranchOfficeTable"
        row={brachOffices}
        columns={columns}
        defaultColumnWidths={columns.map((column) => ({ columnName: column.name, width: 'auto' }))}
        columnExtensions={editingStateColumnExtensions}
        dataTypeProvider={Provider}
        defaultHiddenColumnNames={defaultHiddenColumn}
        editable
        showAddCommand
      />
    </>
  );
}

export default AllBrachofficeTable;
