import React from 'react';
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';

interface Props {
  open: boolean;
}

function LoadingDialog({ open }: Props) {
  return (
    <Dialog aria-labelledby="loading-dialog" open={open}>
      <DialogTitle id="loading-dialog">Daten werden geladen...</DialogTitle>
      <DialogContent id="loading-dialog">
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default LoadingDialog;
