import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';

interface Props {
  onExecute: () => void;
}

const CommitButton = ({ onExecute }: Props) => (
  <IconButton onClick={onExecute} title="Save changes">
    <SaveIcon />
  </IconButton>
);

export default CommitButton;
