import React, { useState, useContext, useRef, useCallback, useEffect, ElementType } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Paper, FormControl, FormControlLabel, Switch, Button, TextField } from '@material-ui/core';
import columnWidths from './columnsWidths';
import {
  Grid,
  TableColumnVisibility,
  Table,
  TableHeaderRow,
  PagingPanel,
  ColumnChooser,
  TableFilterRow,
  TableColumnResizing,
  SearchPanel,
  Toolbar,
  ExportPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  SearchState,
  Filter,
  Sorting,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import saveAs from 'file-saver';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import BrachOfficeFilterChoice from '../../../components/BranchofficeChoiseButtons/BrachofficeBtnContainer';
import DataContext from '../../../context/DataProvider/DataContext';
import columns from './columns';
import shopifyColumns from './ShopifyColumns';
import propertyOf from '../../../services/propertyOf';
import { Agent, AgentStatus } from '../../../types/Agent.types';
import { DateRange } from '@material-ui/icons';
import RowsProvider from './RowsProvider';

// @ts-expect-error
const onSaveFilterXlsx = (workbook) => {
  // @ts-expect-error
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'FilterDataGrid.xlsx');
  });
};
// @ts-expect-error
const onSaveXlsx = (workbook) => {
  // @ts-expect-error
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
  });
};
// @ts-expect-error
const onSaveCSV = (workbook) => {
  // @ts-expect-error
  workbook.csv.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'shopify.csv');
  });
};

interface PropeFilter {
  type: any;
  restProps: any;
  component: ElementType<any>;
}

const FilterIcon = ({ type, ...restProps }: PropeFilter) => {
  if (type === 'month') return <DateRange {...restProps} />;
  return <TableFilterRow.Icon type={type} {...restProps} />;
};

function AllAgentsTable() {
  const { brachOffices, agents, status, loaderFun } = useContext(DataContext);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageSizes] = useState([10, 25, 50, 100]);
  const [sorting, setSorting] = useState<Sorting[]>([{ columnName: 'branch_office', direction: 'asc' }]);
  const [defaultColumnWidths] = useState(columnWidths);
  const [defaultHiddenColumnNames] = useState(['id', 'statusID']);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [isDismissedAgentFilter, setIsDismissedAgentFilter] = useState(false);
  const [dateColumns] = useState(['birthday', 'start_date', 'dismissed_at', 'business_registration_date']);
  const [dateFilterOperations] = useState(['month', 'contains', 'startsWith', 'endsWith']);
  const [filteringColumnExtensions] = useState([
    {
      columnName: 'birthday',
      predicate: (value: any, filter: any, row: any) => {
        if (!filter.value.length) return true;
        if (filter && filter.operation === 'month') {
          const month = parseInt(value.split('-')[1], 10);
          return month === parseInt(filter.value, 10);
        }
        return IntegratedFiltering.defaultPredicate(value, filter, row);
      },
    },
    {
      columnName: 'start_date',
      predicate: (value: any, filter: any, row: any) => {
        if (!filter.value.length) return true;
        if (filter && filter.operation === 'month') {
          if (value === null) {
            return false;
          }
          const month = parseInt(value.split('-')[1], 10);
          return month === parseInt(filter.value, 10);
        }
        return IntegratedFiltering.defaultPredicate(value, filter, row);
      },
    },
    {
      columnName: 'dismissed_at',
      predicate: (value: any, filter: any, row: any) => {
        if (!filter.value.length) return true;
        if (filter && filter.operation === 'month') {
          if (value === null) {
            return false;
          }
          const month = parseInt(value.split('-')[1], 10);
          return month === parseInt(filter.value, 10);
        }
        return IntegratedFiltering.defaultPredicate(value, filter, row);
      },
    },
    {
      columnName: 'business_registration_date',
      predicate: (value: any, filter: any, row: any) => {
        if (!filter.value.length) return true;
        if (filter && filter.operation === 'month') {
          if (value === null) {
            return false;
          }
          const month = parseInt(value.split('-')[1], 10);
          return month === parseInt(filter.value, 10);
        }
        return IntegratedFiltering.defaultPredicate(value, filter, row);
      },
    },
  ]);
  const [exportedRows, setExportedRows] = useState([]);

  const refExportFilter = useRef<typeof GridExporter>(null);
  const refExportApp = useRef<typeof GridExporter>(null);
  const refExportShopify = useRef<typeof GridExporter>(null);

  useEffect(() => {
    if (!status) {
      loaderFun.loadStatus();
    }
  }, []);

  const startFilterExport = useCallback(() => {
    refExportFilter.current?.exportGrid({ selectedOnly: true });
  }, [refExportFilter]);

  const startExport = useCallback(() => {
    refExportApp.current?.exportGrid();
  }, [refExportApp]);

  const startShopifyExport = useCallback(() => {
    refExportShopify.current?.exportGrid();
  }, [refExportShopify]);

  const TableRow = useCallback(
    ({ row, children, tableRow }: Table.DataRowProps) => (
      <Table.Row
        key={Math.random()}
        tableRow={tableRow}
        row={row}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        onClick={() => {
          history.push(`/agent/${row.agent_id}`);
        }}
        style={{ cursor: 'pointer' }}
      />
    ),
    [],
  );

  const changeCurrentPage = useCallback((value) => {
    setCurrentPage(value);
  }, []);
  const changePageSize = useCallback((value) => {
    setPageSize(value);
  }, []);

  const setBrancheOfficeFilter = useCallback(
    (officeTitle) => {
      const currentFilterList = filters;
      const index = currentFilterList.findIndex((filter) => filter.columnName === 'branch_office');
      if (index !== -1) {
        currentFilterList[index] = { columnName: 'branch_office', operation: 'equal', value: officeTitle };
        setFilters([...currentFilterList]);
      } else {
        setFilters([...filters, { columnName: 'branch_office', operation: 'equal', value: officeTitle }]);
      }
    },
    [filters],
  );

  const allFilterReset = useCallback(() => {
    setFilters([]);
    setIsDismissedAgentFilter(false);
  }, []);

  const ToolbarRootBase = useCallback(
    ({ children }) => (
      <Toolbar.Root>
        <BrachOfficeFilterChoice
          brachofficeData={brachOffices}
          setBrancheOfficeFilter={setBrancheOfficeFilter}
          allFilterReset={allFilterReset}
        />

        {children}
        <Box marginLeft={2}>
          <FormControl>
            <FormControlLabel
              data-testid="dismissed-agent-formcontrol"
              control={
                <Switch
                  color="primary"
                  checked={isDismissedAgentFilter}
                  disabled={agents.length === 0 || status.length === 0}
                  onChange={(event) => {
                    setIsDismissedAgentFilter(event.target.checked);
                    if (event.target.checked) {
                      setFilters([
                        ...filters,
                        {
                          columnName: propertyOf<Agent>('status'),
                          operation: 'equal',
                          value: status.find((item) => item.id === AgentStatus.dismissed)?.title || '',
                        },
                      ]);
                    } else {
                      const currentFilterList = filters;
                      const index = currentFilterList.findIndex(
                        (filter) => filter.columnName === propertyOf<Agent>('status'),
                      );
                      if (index !== -1) {
                        currentFilterList.splice(index);
                        setFilters([...currentFilterList]);
                      }
                    }
                  }}
                />
              }
              label="Gekündigte Berater"
            />
          </FormControl>
        </Box>
      </Toolbar.Root>
    ),
    [agents, isDismissedAgentFilter, status],
  );

  const exportMenu = useCallback(
    () => (
      <Box display="flex" flexDirection="column">
        <Button onClick={startExport}>Alle</Button>
        <Button onClick={startFilterExport}>Filter</Button>
        <Button onClick={startShopifyExport}>Shopify</Button>
      </Box>
    ),
    [],
  );

  return (
    <Paper>
      <Grid rows={agents} columns={columns}>
        {/* <NumberTypeProvider /> */}
        <DataTypeProvider for={dateColumns} availableFilterOperations={dateFilterOperations} />
        <SearchState defaultValue="" />
        <FilteringState filters={filters} onFiltersChange={setFilters} />
        <IntegratedFiltering columnExtensions={filteringColumnExtensions} />
        <RowsProvider onRowsUpdate={setExportedRows} />
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={changeCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={changePageSize}
        />
        <IntegratedPaging />
        <IntegratedSorting />
        <Table rowComponent={TableRow} />
        <TableColumnResizing columnWidths={defaultColumnWidths} />
        <TableHeaderRow showSortingControls />
        <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
        {/* @ts-ignore */}
        <TableFilterRow showFilterSelector iconComponent={FilterIcon} messages={{ month: 'Month equals' }} />
        <Toolbar rootComponent={ToolbarRootBase} />
        <SearchPanel />
        <ColumnChooser />
        <PagingPanel pageSizes={pageSizes} />
        <ExportPanel menuItemComponent={exportMenu} />
      </Grid>

      <GridExporter ref={refExportFilter} rows={exportedRows} columns={columns} onSave={onSaveFilterXlsx} />
      <GridExporter ref={refExportApp} rows={agents} columns={columns} onSave={onSaveXlsx} />
      <GridExporter ref={refExportShopify} rows={agents} columns={shopifyColumns} onSave={onSaveCSV} />
    </Paper>
  );
}

export default AllAgentsTable;
