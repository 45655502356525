import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getCurrentUser, getToken } from './modules/context/CommunicationProvider/authUser';
import SiteLayout from './modules/components/SiteLayout';
import LoginPage from './modules/pages/LoginPage';
import LogOut from './modules/components/LogOut';
import pages, { Page } from './pages';

interface Props {
  setUserToken: React.Dispatch<React.SetStateAction<string>>;
  setApolloClientToken?: () => void;
}

function Main({ setUserToken, setApolloClientToken }: Props) {
  useEffect(() => {
    const token = getToken();
    if (token) {
      setUserToken(token);
    }
  }, []);

  return (
    <Switch>
      {pages.map((item: Page) => (
        <Route
          key={Math.random()}
          exact
          path={item.path}
          render={() => {
            if (!getCurrentUser(setApolloClientToken)) return <Redirect to="/login" />;
            return <SiteLayout title={item.title} main={item.render} />;
          }}
        />
      ))}
      <Route exact path="/login" render={() => <LoginPage />} />
      <Route exact path="/logout" component={LogOut} />
      <Route render={() => <>Page not Found 404</>} />
    </Switch>
  );
}

export default Main;
