import React from 'react';
import clsx from 'clsx';
import HeaderSearch from './HeaderSearch';
import HeaderControl from './HeaderControl';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import ThemeOption from '../../../services/themeOption';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  header: {
    background: 'white',
    paddingLeft: 0,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  blockIconMenu: {
    background: () => theme.aside.asideHeaderBackgroundColor,
    height: '90px',
    width: 75,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 0,
    marginLeft: 0,
    color: () => theme.aside.asideHeaderChevronColor,
    opacity: 1,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    cursor: 'pointer',
  },
  hide: {
    display: 'none',
    width: 75,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  icon: {
    fontSize: '32px',
  },
}));

function HeaderApp({ open, handleDrawerOpen, allAgents }) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={ThemeOption}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.header}>
          <div
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.blockIconMenu, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon className={classes.icon} />
          </div>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={10}>
              <HeaderSearch allAgents={allAgents} />
            </Grid>
            <Grid item xs={2}>
              <HeaderControl />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}
export default HeaderApp;
