import React from 'react';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Service } from '../../../../../../types/Service.types';
import DialogAddServiceToAgent from '../../../../../AgentEditPage/components/AgentServicesTable/components/DialogAddServiceToAgent';

interface Props {
  onExecute: () => void;
  forServicesById?: { tableName: string; services: Service[]; agent_id: string | undefined; refresh: () => void };
}

function AddButton({ onExecute, forServicesById }: Props) {
  switch (forServicesById?.tableName) {
    case 'AgentServiceTable':
      return (
        <div style={{ textAlign: 'center' }}>
          <DialogAddServiceToAgent
            allServices={forServicesById?.services}
            agent_id={forServicesById?.agent_id}
            refreshServicesById={forServicesById?.refresh}
          />
        </div>
      );
    default:
      return (
        <IconButton onClick={onExecute}>
          <AddIcon />
        </IconButton>
      );
  }
}

export default AddButton;
