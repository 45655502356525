import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import isPostfix from '../services/isPostfix';

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

const EmailEditor = ({ value, onValueChange }: Props) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [errorEmptyString, setErrorEmptyString] = useState({ des: true, message: 'Falscher Eintrag' });

  useEffect(() => {
    if (value !== '' && isPostfix(value)) {
      setErrorEmptyString({ des: false, message: '' });
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    setInputValue(newValue);
    onValueChange(newValue);
    if (inputValue !== '') {
      if (isPostfix(inputValue)) {
        setErrorEmptyString({ des: false, message: '' });
      } else {
        setErrorEmptyString({ des: true, message: 'Emailpostfix fehlt' });
      }
    }
  };

  return (
    <TextField
      value={inputValue}
      onChange={handleChange}
      error={errorEmptyString.des}
      helperText={errorEmptyString.message}
    />
  );
};

export default EmailEditor;
