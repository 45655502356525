import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles, useTheme, ThemeProvider } from '@material-ui/core/styles';
import Dashboard from '@material-ui/icons/Dashboard';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EuroIcon from '@material-ui/icons/Euro';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CreateIcon from '@material-ui/icons/Create';
import ExitToApp from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MapIcon from '@material-ui/icons/Map';
import ThemeOption from '../../../services/themeOption';
import handleClearStorage from '../../../pages/AgentEditPage/services/handleClearStorage';
import ConfirmExitAppModal from './components/ConfirmExitAppModal';
import { PagePathname } from '../../../../pages';
import { LocalStorageKeys } from '../../../pages/AgentEditPage/services/handleFormChange';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: () => ThemeOption.aside.asideBackgroundColor,
  },
  aside: {
    height: '98vh',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    height: '90px',
    [theme.breakpoints.up('md')]: {
      minHeight: '90px',
    },
  },
  navItem: {
    '&:hover': { background: ThemeOption.aside.asideHoverBgColor },
    background: () => ThemeOption.aside.asideBackgroundColor,
    color: () => ThemeOption.aside.asideNavTextColor,
  },
  navIcon: {
    minWidth: '40px',
  },
  navIconText: {
    color: () => ThemeOption.palette.primary.main,
  },
  navSubItem: {
    color: () => ThemeOption.aside.asideNavTextColor,
    paddingLeft: '4.5rem',
  },
  link: {
    textDecoration: 'none',
    color: () => ThemeOption.aside.asideNavTextColor,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'rgb(54, 66, 87)',
    width: '30px',
  },
}));

function AsideNavbar({ handleDrawerClose, title }) {
  const classes = useStyles();
  const theme = useTheme();
  const [openFinance, setOpenFinance] = React.useState(false);
  const [openOptions, setOpenOptions] = React.useState(false);
  const [openReports, setOpenReports] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState('Dashboard');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  function handleSelectItem(selectedItem) {
    setActiveItem(selectedItem);
  }

  const changeOpenFinance = () => {
    setOpenFinance(!openFinance);
  };

  const changeOpenOptions = () => {
    setOpenOptions(!openOptions);
  };

  const changeOpenReports = () => {
    setOpenReports(!openReports);
  };

  const handleExitClick = () => {
    const formData = localStorage.getItem(LocalStorageKeys.formDataCreateAgent);
    const formDataFirstLoad = localStorage.getItem(LocalStorageKeys.formDataCreateAgentFirstLoad);

    if (formData !== formDataFirstLoad) {
      setIsModalOpen(true);
    } else {
      proceedToLogout();
    }
  };

  function closeModal() {
    setIsModalOpen(false);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
    handleSelectItem('Exit to app');
    handleClearStorage();
    history.push(PagePathname.logout);
  };

  const proceedToLogout = () => {
    handleSelectItem('Exit to app');
    handleClearStorage();
    history.push(PagePathname.logout);
  };

  return (
    <ThemeProvider theme={ThemeOption}>
      <Box className={classes.toolbar} bgcolor={ThemeOption.aside.asideHeaderBackgroundColor}>
        {/* <Avatar className={classes.avatar} src={shortLogo} variant="square"></Avatar> */}
        <Typography variant="h6">
          <Box
            color={ThemeOption.aside.headerAsideColor}
            fontSize={ThemeOption.aside.fontAsideHeader}
            fontWeight="normal"
          >
            PRM
          </Box>
        </Typography>
        <IconButton aria-label="menu-close-open" onClick={handleDrawerClose}>
          <Box color={ThemeOption.aside.headerAsideColor}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </Box>
        </IconButton>
      </Box>
      <Box className={classes.aside} bgcolor={ThemeOption.aside.asideBackgroundColor}>
        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
          <Link className={classes.link} to={PagePathname.dashboard}>
            <ListItem button className={classes.navItem}>
              <ListItemIcon>
                <Dashboard className={classes.navIcon} color={title === 'Dashboard' ? 'primary' : 'secondary'} />
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: title === 'Dashboard' ? classes.navIconText : ' ',
                }}
                primary="Dashboard"
              />
            </ListItem>
          </Link>

          <Link className={classes.link} to={PagePathname.alleBerater}>
            <ListItem button className={classes.navItem}>
              <ListItemIcon>
                <PeopleAltIcon className={classes.navIcon} color={title === 'Berater' ? 'primary' : 'secondary'} />
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: title === 'Berater' ? classes.navIconText : ' ',
                }}
                primary="Berater"
              />
            </ListItem>
          </Link>

          <Link className={classes.link} to={PagePathname.karte}>
            <ListItem button className={classes.navItem}>
              <ListItemIcon>
                <MapIcon className={classes.navIcon} color={title === 'Karte' ? 'primary' : 'secondary'} />
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: title === 'Karte' ? classes.navIconText : ' ',
                }}
                primary="Karte"
              />
            </ListItem>
          </Link>

          <div className={classes.link}>
            <ListItem
              button
              className={classes.navItem}
              onClick={() => {
                changeOpenReports();
              }}
            >
              <ListItemIcon>
                <FormatListBulletedIcon
                  className={classes.navIcon}
                  color={title === 'Berichte' ? 'primary' : 'secondary'}
                />
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: title === 'Berichte' ? classes.navIconText : ' ',
                }}
                primary="Berichte"
              />
              {openReports ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openReports} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link className={classes.link} to={PagePathname.servicesReport}>
                  <ListItem button className={classes.navSubItem}>
                    <ListItemText
                      classes={{
                        root: title === 'Leistungen' ? classes.navIconText : ' ',
                      }}
                      primary="Leistungen"
                    />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          </div>

          <div className={classes.link}>
            <ListItem
              button
              className={classes.navItem}
              onClick={() => {
                changeOpenFinance();
              }}
            >
              <ListItemIcon>
                <EuroIcon className={classes.navIcon} color={title === 'Buchhaltung' ? 'primary' : 'secondary'} />
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: title === 'Buchhaltung' ? classes.navIconText : ' ',
                }}
                primary="Buchhaltung"
              />
              {openFinance ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openFinance} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link className={classes.link} to={PagePathname.alleBelege}>
                  <ListItem button className={classes.navSubItem}>
                    <ListItemText
                      classes={{
                        root: title === 'Belege' ? classes.navIconText : ' ',
                      }}
                      primary="Belege"
                    />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
            <Collapse in={openFinance} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link className={classes.link} to={PagePathname.belegeErstellen}>
                  <ListItem button className={classes.navSubItem}>
                    <ListItemText
                      classes={{
                        root: title === 'Belege erstellen' ? classes.navIconText : ' ',
                      }}
                      primary="Belege erstellen"
                    />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
            <Collapse in={openFinance} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link className={classes.link} to={PagePathname.monatlicherAbschluss}>
                  <ListItem button className={classes.navSubItem}>
                    <ListItemText
                      classes={{
                        root: title === 'Monatlicher Abschluss' ? classes.navIconText : ' ',
                      }}
                      primary="Monatlicher Abschluss"
                    />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
            <Collapse in={openFinance} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link className={classes.link} to={PagePathname.shopifyBelege}>
                  <ListItem button className={classes.navSubItem}>
                    <ListItemText
                      classes={{
                        root: title === 'Shopify Belege' ? classes.navIconText : ' ',
                      }}
                      primary="Shopify Belege"
                    />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          </div>

          <div className={classes.link}>
            <ListItem
              button
              className={classes.navItem}
              onClick={() => {
                changeOpenOptions();
              }}
            >
              <ListItemIcon>
                <CreateIcon className={classes.navIcon} color={title === 'Einstellungen' ? 'primary' : 'secondary'} />
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: title === 'Einstellungen' ? classes.navIconText : ' ',
                }}
                primary="Einstellungen"
              />
              {openOptions ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openOptions} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link className={classes.link} to={PagePathname.buchungskonten}>
                  <ListItem button className={classes.navSubItem}>
                    <ListItemText
                      classes={{
                        root: title === 'Buchungskonten' ? classes.navIconText : ' ',
                      }}
                      primary="Buchungskonten"
                    />
                  </ListItem>
                </Link>
                <Link className={classes.link} to={PagePathname.ränge}>
                  <ListItem button className={classes.navSubItem}>
                    <ListItemText
                      classes={{
                        root: title === 'Ränge' ? classes.navIconText : ' ',
                      }}
                      primary="Ränge"
                    />
                  </ListItem>
                </Link>
                <Link className={classes.link} to={PagePathname.levels}>
                  <ListItem button className={classes.navSubItem}>
                    <ListItemText
                      classes={{
                        root: title === 'Levels' ? classes.navIconText : ' ',
                      }}
                      primary="Levels"
                    />
                  </ListItem>
                </Link>
                <Link className={classes.link} to={PagePathname.benutzer}>
                  <ListItem button className={classes.navSubItem}>
                    <ListItemText
                      classes={{
                        root: title === 'Benutzer' ? classes.navIconText : ' ',
                      }}
                      primary="Benutzer"
                    />
                  </ListItem>
                </Link>
                <Link className={classes.link} to={PagePathname.marken}>
                  <ListItem button className={classes.navSubItem}>
                    <ListItemText
                      classes={{
                        root: title === 'Marken' ? classes.navIconText : ' ',
                      }}
                      primary="Marken"
                    />
                  </ListItem>
                </Link>
                <Link className={classes.link} to={PagePathname.leistungen}>
                  <ListItem button className={classes.navSubItem}>
                    <ListItemText
                      classes={{
                        root: title === 'Leistungen' ? classes.navIconText : ' ',
                      }}
                      primary="Leistungen"
                    />
                  </ListItem>
                </Link>
                <Link className={classes.link} to={PagePathname.steuern}>
                  <ListItem button className={classes.navSubItem}>
                    <ListItemText
                      classes={{
                        root: title === 'Steuern' ? classes.navIconText : ' ',
                      }}
                      primary="Steuern"
                    />
                  </ListItem>
                </Link>
                <Link className={classes.link} to={PagePathname.länder}>
                  <ListItem button className={classes.navSubItem}>
                    <ListItemText
                      classes={{
                        root: title === 'Länder' ? classes.navIconText : ' ',
                      }}
                      primary="Länder"
                    />
                  </ListItem>
                </Link>
                <Link className={classes.link} to={PagePathname.vertragsarten}>
                  <ListItem button className={classes.navSubItem}>
                    <ListItemText
                      classes={{
                        root: title === 'Vertragsarten' ? classes.navIconText : ' ',
                      }}
                      primary="Vertragsarten"
                    />
                  </ListItem>
                </Link>
                <Link className={classes.link} to={PagePathname.adminPanel}>
                  <ListItem button className={classes.navSubItem}>
                    <ListItemText
                      classes={{
                        root: title === 'Info' ? classes.navIconText : ' ',
                      }}
                      primary="Info"
                    />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          </div>
          <ListItem button className={classes.navItem} onClick={() => handleExitClick()}>
            <ListItemIcon>
              <ExitToApp className={classes.navIcon} color={activeItem === 'Exit ' ? 'primary' : 'secondary'} />
            </ListItemIcon>
            <ListItemText
              classes={{
                root: activeItem === 'Exit ' ? classes.navIconText : ' ',
              }}
              primary="Exit "
            />
          </ListItem>
          <ConfirmExitAppModal
            open={isModalOpen}
            onClose={handleCloseModal}
            closeModal={closeModal}
            link={PagePathname.beraterErstellen}
          />
        </List>
      </Box>
    </ThemeProvider>
  );
}

export default AsideNavbar;
