import React from 'react';
import { useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import ThemeOption from '../../../services/themeOption';
import AddBoxIcon from '@material-ui/icons/PersonAdd';
import NoteAdd from '@material-ui/icons/NoteAdd';
import { ThemeProvider } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '32px',
    cursor: 'pointer',
    color: '#404D66',
    '&:hover': { color: '#56CCF2' },
  },
  iconInteressent: {
    fontSize: '37px',
    cursor: 'pointer',
    color: '#404D66',
    '&:hover': { color: '#56CCF2' },
  },
  link: {
    textDecoration: 'none',
  },
}));

function HeaderControl() {
  let history = useHistory();
  const classes = useStyles();
  return (
    <ThemeProvider theme={ThemeOption}>
      <Box pr={4}>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            <Tooltip title="Berater erstellen">
              <Link
                className={classes.link}
                onClick={() => {
                  history.push('/create-agent');
                }}
              >
                <AddBoxIcon className={classes.icon} />
              </Link>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Beleg erstellen">
              <Link
                className={classes.link}
                onClick={() => {
                  history.push('/receipt-create');
                }}
              >
                <NoteAdd className={classes.icon} />
              </Link>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default HeaderControl;
