import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import onlyWhitespaceInString from '../../../../../services/onlyWhitespaceInString';
import DataContext from '../../../../../context/DataProvider/DataContext';
import { ContractType } from '../../../../../types/ContractType.types';

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

function validateValue(
  value: Props['value'],
  listOfContractTypes: ContractType[],
): { error: boolean; helperText: string } {
  let nameIsIdentical = false;
  for (let index = 0; index < listOfContractTypes.length; index++) {
    if (value === listOfContractTypes[index].title) {
      nameIsIdentical = true;
    }
  }

  if (!value) {
    return {
      error: true,
      helperText: 'Pflichtfeld',
    };
  } else if (onlyWhitespaceInString(value)) {
    return {
      error: true,
      helperText: 'Darf nicht nur aus Leerzeichen bestehen',
    };
  } else if (value.length > 255) {
    return {
      error: true,
      helperText: 'Max 255 Zeichen lang',
    };
  } else if (nameIsIdentical) {
    return {
      error: true,
      helperText: 'Titel ist bereits vorhanden',
    };
  } else {
    return {
      error: false,
      helperText: '',
    };
  }
}

function TextEditor({ value, onValueChange }: Props) {
  const { contractType } = useContext(DataContext);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  useEffect(() => {
    const valid = validateValue(value, contractType);
    setError(valid.error);
    setHelperText(valid.helperText);
  }, [value]);
  return (
    <Box>
      <TextField
        value={value || ''}
        onChange={(event) => onValueChange(event.target.value)}
        error={error}
        helperText={helperText}
      />
    </Box>
  );
}

export default TextEditor;
