import React, { useContext, useEffect } from 'react';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import DataContext from '../../../../../context/DataProvider/DataContext';

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

function BookingAccountEditor({ value, onValueChange }: Props): JSX.Element {
  const { bookingAccount, loaderFun } = useContext(DataContext);

  useEffect(() => {
    loaderFun.loadBookingAccount();
  }, []);

  return (
    <FormControl fullWidth error>
      <Select
        input={<Input />}
        value={value || ''}
        onChange={(event) => onValueChange(event.target.value as string)}
        style={{ width: '100%' }}
        error={!value}
      >
        {bookingAccount.map((bookingAccountItem) => (
          <MenuItem key={bookingAccountItem.id} value={bookingAccountItem.title}>
            {bookingAccountItem.title}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{value ? '' : 'Incorrect entry'}</FormHelperText>
    </FormControl>
  );
}

export default BookingAccountEditor;

