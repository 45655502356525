import React, { useContext, useState } from 'react';
import {
  Box,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DebounceTextField from '../DebounceTextfield';
import { BranchOffice } from '../../types/BranchOffice.types';
import updateBranchOfficeQuery from '../../Apollo/mutations/updateBranchOffice';
import DataContext from '../../context/DataProvider/DataContext';
import branchOfficeValidationSchema from '../../types/validSchemas/BranchOffice.valid';
import { BranchofficeUpdate } from '../../types/BranchOfficeUpdate';
import isDefaultOffice from './services/isDefaultService';
import isDefault from './services/isDefault';
import createBranchOfficeQuery from '../../Apollo/mutations/createBranchOffice';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import InitOfficeValue from '../../types/initValues/InitOfficeValue.init';
import BranchofficeInput from '../../types/BranchOfficeInput.types';
import LoadingDialog from '../LoadingDialog';

interface EditTableRowFormDialogProps {
  rowValue?: BranchOffice;
}

function EditTableRowFormDialog({ rowValue }: EditTableRowFormDialogProps) {
  const { loaderFun } = useContext(DataContext);
  const [updateBranchOffice, { loading: loadingAfterUpdate }] = useMutation(updateBranchOfficeQuery);
  const [createBranchOffice, { loading: loadingAfterCreate }] = useMutation(createBranchOfficeQuery);
  const [open, setOpen] = useState(false);
  const handleClose = (): void => {
    setOpen(false);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={rowValue ?? InitOfficeValue}
      validationSchema={branchOfficeValidationSchema}
      onSubmit={(values) => {
        let officeID = values.id;
        let isDefaultBranchoffice;
        if (values.is_default) {
          isDefaultBranchoffice = isDefaultOffice(values.is_default);
        }

        let branchofficeUpdateInput: BranchofficeUpdate = {
          title: values.title?.trim(),
          domain: values.domain?.trim(),
          email_postfix: values.email_postfix?.trim(),
          min_agent_id: values.min_agent_id,
          max_agent_id: values.max_agent_id,
          is_default: isDefaultBranchoffice,
          zip: values.zip?.trim(),
          city: values.city?.trim(),
          address: values.address?.trim(),
          phone: values.phone?.trim(),
          fax: values.fax?.trim(),
          email: values.email?.trim(),
        };
        let branchofficeCreateIput: BranchofficeInput = {
          title: values.title?.trim(),
          domain: values.domain?.trim(),
          email_postfix: values.email_postfix?.trim(),
          min_agent_id: parseInt(values.min_agent_id.toString()),
          max_agent_id: parseInt(values.max_agent_id.toString()),
          is_default: isDefault(values.is_default),
          zip: values.zip?.trim(),
          city: values.city?.trim(),
          address: values.address?.trim(),
          phone: values.phone?.trim(),
          fax: values.fax?.trim(),
          email: values.email?.trim(),
          show_in_export_xml: values.show_in_export_xml,
        };
        if (officeID) {
          updateBranchOffice({ variables: { variables: branchofficeUpdateInput, id: officeID } }).then((result) => {
            if (result.data.updateBranchOffice) loaderFun.loadBranchOffice();
          });
        } else {
          createBranchOffice({ variables: { variables: branchofficeCreateIput } }).then((result) => {
            if (result.data.createBranchOffice) loaderFun.loadBranchOffice();
          });
        }
      }}
    >
      {({ values, handleSubmit, handleChange, handleBlur, errors, isValid, resetForm, dirty, validateForm }) => (
        <Box sx={{ paddingTop: '5%' }}>
          <LoadingDialog open={loadingAfterUpdate || loadingAfterCreate} />
          <IconButton
            onClick={() => {
              handleOpen();
            }}
          >
            {rowValue ? (
              <Box
                onClick={() => {
                  validateForm();
                }}
              >
                <EditIcon />
              </Box>
            ) : (
              <Box
                onClick={() => {
                  validateForm();
                }}
              >
                <AddIcon />
              </Box>
            )}
          </IconButton>
          <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="xl">
            <DialogTitle>{values.id ? 'Firmendaten bearbeiten' : 'Neue Firma angelgen'}</DialogTitle>
            <DialogContent>
              <Grid container direction="row" justifyContent="center" spacing={1} style={{ overflow: 'hidden' }}>
                <Grid item xs>
                  <DebounceTextField
                    label="Titel"
                    name="title"
                    aria-label="title"
                    value={values.title}
                    onChange={handleChange}
                    variant="standard"
                    helperText={errors.title}
                    error={Boolean(errors.title) || false}
                    fullWidth
                  />
                  <DebounceTextField
                    label="Email postfix"
                    name="email_postfix"
                    aria-label="email_postfix"
                    value={values.email_postfix}
                    onChange={handleChange}
                    variant="standard"
                    helperText={errors.email_postfix}
                    error={Boolean(errors.email_postfix) || false}
                    fullWidth
                  />
                </Grid>
                <Grid item xs>
                  <DebounceTextField
                    label="min Berater-Nummer"
                    name="min_agent_id"
                    aria-label="min_agent_id"
                    value={values.min_agent_id}
                    onChange={handleChange}
                    variant="standard"
                    helperText={errors.min_agent_id}
                    error={Boolean(errors.min_agent_id) || false}
                    fullWidth
                  />
                  <DebounceTextField
                    label="max Berater-Nummer"
                    name="max_agent_id"
                    aria-label="max_agent_id"
                    value={values.max_agent_id}
                    onChange={handleChange}
                    variant="standard"
                    helperText={errors.max_agent_id}
                    error={Boolean(errors.max_agent_id) || false}
                    fullWidth
                  />
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth variant="standard" error={Boolean(errors.is_default) || false}>
                    <InputLabel>Standart</InputLabel>
                    <Select
                      name="is_default"
                      value={values.is_default ? 'Yes' : 'No'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="standard"
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                    <FormHelperText>{errors.is_default}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <DebounceTextField
                    label="Telefon"
                    name="phone"
                    aria-label="phone"
                    value={values.phone}
                    onChange={handleChange}
                    variant="standard"
                    helperText={errors.phone}
                    error={Boolean(errors.phone) || false}
                    fullWidth
                  />
                  <DebounceTextField
                    label="Fax"
                    name="fax"
                    aria-label="fax"
                    value={values.fax}
                    onChange={handleChange}
                    variant="standard"
                    helperText={errors.fax}
                    error={Boolean(errors.fax) || false}
                    fullWidth
                  />
                </Grid>
                <Grid item xs>
                  <DebounceTextField
                    type="input"
                    label="Email"
                    aria-label="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="standard"
                    helperText={errors.email}
                    error={Boolean(errors.email) || false}
                    fullWidth
                  />
                  <DebounceTextField
                    aria-label="address"
                    label="Straße"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.address}
                    error={Boolean(errors.address) || false}
                    fullWidth
                  />
                </Grid>
                <Grid item xs>
                  <DebounceTextField
                    aria-label="zip"
                    label="PLZ"
                    name="zip"
                    value={values.zip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.zip}
                    error={Boolean(errors.zip) || false}
                    fullWidth
                  />
                </Grid>
                <Grid item xs>
                  <DebounceTextField
                    label="Stadt"
                    name="city"
                    aria-label="city"
                    value={values.city}
                    onChange={handleChange}
                    variant="standard"
                    helperText={errors.city}
                    error={Boolean(errors.city) || false}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions disableSpacing={false}>
              <Button
                autoFocus
                onClick={() => {
                  resetForm();
                  handleClose();
                }}
                variant="outlined"
              >
                Abbrechen
              </Button>

              <Button
                onClick={() => {
                  handleSubmit();
                  handleClose();
                }}
                variant="outlined"
                disabled={!isValid || !dirty}
              >
                Speichern
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Formik>
  );
}
export default EditTableRowFormDialog;
