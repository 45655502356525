import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Tabs, Tab, Grid, CircularProgress, Typography, Tooltip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Formik, FormikProps } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import DataContext from '../../context/DataProvider/DataContext';
import AgentValid from '../../types/validSchemas/Agent.valid';
import AgentFormPersonalData from './components/AgentFormPersonalData';
import AgentFormContactsData from './components/AgentFormContactsData';
import AgentFormMainInfo from './components/AgentFormMainInfo';
import AgentFormDeliveryAddress from './components/AgentFormDeliveryAddress';
import AgentFormBillingAddress from './components/AgentFormBillingAddress';
import AgentFormBusinessInfo from './components/AgentFormBusinessInfo';
import InitAgentInput from '../../types/initValues/AgentInput';
import { AgentInputType } from '../../types/AgentInputType.types';
import SexInit from '../../types/initValues/Sex.init';
import SmallBusinessInit from '../../types/initValues/SmallBusiness.init';
import AgentServices from '../../components/AgentEdit/AgentServices';
import EditAvatar from './components/EditAvatar';
import TabPanel from './TabPanel';
import AgentProjects from '../../components/AgentEdit/AgentProjects';
import AgentFormDismiss from './components/AgentFormDismiss';
import Agentv2 from '../../types/Agentv2.types';
import updateAgentQuery from '../../Apollo/mutations/updateAgent';
import { AgentStatus } from '../../types/Agent.types';
import DebounceTextField from '../../components/DebounceTextfield';
import AgentServicesTable from './components/AgentServicesTable/index';
import createAgentQuery from '../../Apollo/mutations/createAgent';
import getAgentById from '../../Apollo/queries/getAgentById';
import MUGLLogo from '../../../img/headerColorLogoMUGL.svg';
import TravelistaLogo from '../../../img/headerColorLogoTRAV.svg';
import AERLogo from '../../../img/headerColorLogoAER.svg';
import TRAVELLogo from '../../../img/headerColorLogoTRAVE.svg';
import { useLoadingContext } from '../../context/LoadingProvider/LoadingContext';
import lastDismissedDay from '../../services/lastDismissedDay';
import PRMBackendAgentInput from '../../types/PRMBackendAgentInput.types';
import formatAgentinputForQuery from '../../services/formatAgentInput';
import AgentInit from '../../types/initValues/Agent.init';
import { AgentFilesTable } from './components/AgentFilesTable';
import handleClearStorage from './services/handleClearStorage';
import { LocalStorageKeys } from './services/handleFormChange';

function logoChoser(office_id: number) {
  const logoList = [MUGLLogo, TravelistaLogo, AERLogo, TRAVELLogo];
  let logo = null;
  switch (office_id) {
    case 1:
      logo = logoList[0];
      break;
    case 2:
      logo = logoList[1];
      break;
    case 3:
      logo = logoList[2];
      break;
    case 4:
      logo = logoList[3];
      break;
    default:
      logo = logoList[4];
      break;
  }
  return logo;
}

export default function AgentEditPage() {
  const { agents, status, rank, level, brachOffices, contractType, country, loaderFun, reloadData } =
    useContext(DataContext);
  const { number: AgentID } = useParams<{ number?: string }>();
  const [getAgentData, { data: agentResponse, refetch, loading: loadingAgentData }] = useLazyQuery(getAgentById);
  const [createAgent, { loading: loadingCreateNewAgent }] = useMutation(createAgentQuery);
  const [updateAgent, { loading: laodingUpdateAgent }] = useMutation(updateAgentQuery);
  const [valueTab, setValueTab] = useState(0);
  const { setLoading } = useLoadingContext();
  const history = useHistory();

  setLoading(loadingAgentData || loadingCreateNewAgent || laodingUpdateAgent);

  // Todo back in Context and catch and first App rendering!
  useEffect(() => {
    loaderFun.loadStatus();
    loaderFun.loadRank();
    loaderFun.loadLevel();
    loaderFun.loadBranchOffice();
    loaderFun.loadContractType();
    loaderFun.loadCountry();
  }, []);

  let AgentIDNumber: Number;

  useEffect(() => {
    if (AgentID) {
      AgentIDNumber = parseInt(AgentID, 10);
      getAgentData({ variables: { agent_id: AgentIDNumber } });
    }
  }, [AgentID]);

  function agentInputWithDefaultValues(): AgentInputType {
    let withDefaultValues = { ...InitAgentInput };

    status.filter((statusAgent) => {
      if (statusAgent.is_default) {
        withDefaultValues = { ...withDefaultValues, status_id: +statusAgent.id };
      }
      return null;
    });
    SexInit.filter((sexAgent) => {
      if (sexAgent.is_default) {
        withDefaultValues = { ...withDefaultValues, is_male: +sexAgent.id };
      }
      return null;
    });
    rank.filter((rankItemAgent) => {
      if (rankItemAgent.is_default) {
        withDefaultValues = { ...withDefaultValues, rank_id: +rankItemAgent.id };
      }
      return null;
    });
    level.filter((levelItemAgent) => {
      if (levelItemAgent.is_default) {
        withDefaultValues = { ...withDefaultValues, level_id: +levelItemAgent.id };
      }
      return null;
    });
    brachOffices.filter((officeItemAgent) => {
      if (officeItemAgent.is_default) {
        withDefaultValues = { ...withDefaultValues, branch_office_id: +officeItemAgent.id };
      }
      return null;
    });
    contractType.filter((contractItemAgent) => {
      if (contractItemAgent.is_default) {
        withDefaultValues = { ...withDefaultValues, contract_type_id: +contractItemAgent.id };
      }
      return null;
    });
    country.filter((countryItemAgent) => {
      if (countryItemAgent.is_default) {
        withDefaultValues = { ...withDefaultValues, country_id: +countryItemAgent.id };
      }
      return null;
    });
    SmallBusinessInit.filter((businessItemAgent) => {
      if (businessItemAgent.is_default) {
        withDefaultValues = { ...withDefaultValues, is_small_business: +businessItemAgent.id };
      }
      return null;
    });

    if (localStorage.getItem(LocalStorageKeys.formDataCreateAgent)) {
      let formData = localStorage.getItem(LocalStorageKeys.formDataCreateAgent);
      if (formData) {
        withDefaultValues = JSON.parse(formData);
      }
    }
    return withDefaultValues;
  }

  const isStatus = status.length > 0;
  const isSex = SexInit.length > 0;
  const isRank = rank.length > 0;
  const isLevel = level.length > 0;
  const isBranchOffice = brachOffices.length > 0;
  const isContractType = contractType.length > 0;
  const isCountry = country.length > 0;
  const isSmallBusiness = SmallBusinessInit.length > 0;

  return (
    <div>
      {isRank && isStatus && isLevel && isBranchOffice && isContractType && isCountry && isSmallBusiness && isSex && (
        <Formik
          enableReinitialize
          initialValues={agentResponse?.getAgentv2 || agentInputWithDefaultValues()}
          onSubmit={(values, { setSubmitting }) => {
            const formatetAgentInputValues: PRMBackendAgentInput = formatAgentinputForQuery(values);
            if (values.agent_id === AgentInit.agent_id) {
              createAgent({ variables: { input: formatetAgentInputValues } }).then((result) => {
                reloadData();
                handleClearStorage();
                history.push(`/agent/${result.data.createAgent.agent_id}`);
              });
            } else {
              const formatetAgentInputValues: PRMBackendAgentInput = formatAgentinputForQuery(values);
              delete formatetAgentInputValues.photo;
              updateAgent({
                variables: { agent_id: formatetAgentInputValues.agent_id, input: formatetAgentInputValues },
              }).then((result) => {
                if (result.data.updateAgent) {
                  refetch();
                  setSubmitting(false);
                  reloadData();
                }
              });
            }
          }}
          validationSchema={AgentValid(agents)}
          validateOnMount
        >
          {(props: FormikProps<Agentv2 | AgentInputType>) => {
            const {
              values,
              touched,
              errors,
              dirty,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              isValid,
              isSubmitting,
            } = props;

            const handleChangeValueTab = (event: React.ChangeEvent<{}>, newValue: number) => {
              setValueTab(newValue);
            };

            function isSaveBtnDisabled() {
              if (
                localStorage.getItem(LocalStorageKeys.formDataCreateAgent) !==
                  localStorage.getItem(LocalStorageKeys.formDataCreateAgentFirstLoad) &&
                isValid
              ) {
                return false;
              } else {
                return !isValid || !dirty;
              }
            }

            return (
              <Box bgcolor="#fff" p={2}>
                {values.contract_type_id === 3 && (
                  <Alert variant="filled" severity="info">
                    <div>{`Berater mit der Vertragsart "ProHolidays" werden nicht mit der CentralNode synchronisiert. Haben keinen Zugang zum Cockpit. Vertragsart "ProHolidays" kann nur an Berater der Firma "Mein Urlaubsglück GmbH" vergeben werden.`}</div>
                  </Alert>
                )}
                {values.dismissed_at && (
                  <>
                    <Typography style={{ color: 'red', fontWeight: 'bold' }}>
                      KÜNDIGUNG: Der Berater {values.status_id === AgentStatus.dismissed ? 'WURDE AM' : 'WIRD ZUM'}{' '}
                      {values.dismissed_at.split('T')[0]} gekündigt
                    </Typography>
                    <Typography style={{ color: 'red', fontWeight: 'bold' }}>
                      STATUS: Statusänderung auf "Gekündigt" erfolgt atomatisch am{' '}
                      {lastDismissedDay(values.dismissed_at?.split('T')[0])}
                    </Typography>
                    <Typography style={{ color: 'red', fontWeight: 'bold' }}>
                      KÜNDIGUNGSGRUND: {values.dismissal_reason}
                    </Typography>
                  </>
                )}
                <Tabs value={valueTab} onChange={handleChangeValueTab} indicatorColor="primary" textColor="primary">
                  <Tab data-testid="agentform-tab" label="Agent" />
                  {(agentResponse?.getAgentv2.status_id === AgentStatus.active ||
                    agentResponse?.getAgentv2.status_id === AgentStatus.dismissed) && (
                    <Tab data-testid="agentservices-tab" label="Kosten" />
                  )}
                  <Tab data-testid="agentservices-fileUpload" label="Dokumente" />
                </Tabs>

                <TabPanel value={valueTab} index={0}>
                  <Grid container spacing={2}>
                    <Grid container direction="column" alignItems="center" item xs={12} md={6} lg={2}>
                      <EditAvatar
                        // @ts-expect-error
                        currentAgentAvatar={values.thumbnail}
                        agentID={values.agent_id}
                        formDirty={dirty}
                      />
                      <Box sx={{ paddingTop: 10, justifyContent: 'center', alignItems: 'center' }}>
                        <img src={`${logoChoser(values.branch_office_id)}`} width="220px" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                      <AgentFormPersonalData />
                      <AgentFormContactsData />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                      <AgentFormMainInfo />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                      <AgentFormDeliveryAddress />
                      <AgentFormBillingAddress />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                      <AgentFormBusinessInfo />
                    </Grid>
                    {agentResponse?.getAgentv2.status_id === AgentStatus.active && AgentID && (
                      <Grid item xs={12} md={6} lg={2}>
                        <AgentServices agentId={values.id || ''} />
                        <AgentProjects
                          id={values.id || ''}
                          agentId={values.agent_id || ''}
                          contractId={values.contract_type_id}
                        />
                        <AgentFormDismiss />
                      </Grid>
                    )}
                    <Grid item xs={12} md={12} lg={10}>
                      <DebounceTextField
                        label="Notizen"
                        name="notes"
                        value={values.notes || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        multiline
                        minRows={20}
                        helperText={errors.notes && touched.notes && errors.notes}
                        error={Boolean(errors.notes && touched.notes) || false}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-end" spacing={3}>
                    <Grid item>
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <Button
                          onClick={() => {
                            handleSubmit();
                          }}
                          disabled={isSaveBtnDisabled()}
                          variant="outlined"
                          color="secondary"
                        >
                          Speichern {isSubmitting && <CircularProgress size="20px" />}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => {
                          handleReset();
                        }}
                        disabled={!dirty || isSubmitting}
                        variant="outlined"
                        color="secondary"
                      >
                        Reset
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={valueTab} index={1}>
                  <AgentServicesTable />
                </TabPanel>
                <TabPanel value={valueTab} index={2}>
                  <AgentFilesTable />
                </TabPanel>
              </Box>
            );
          }}
        </Formik>
      )}
    </div>
  );
}
