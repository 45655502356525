import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import ThemeOption from '../../../../services/themeOption';

const useStyles = makeStyles((theme) => ({
  blockInput: {
    width: '100%',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
}));

function ReceiptEditDescription({ receipt, changeReceipt }) {
  const classes = useStyles();
  const [receiptDescription, setReceiptDescription] = useState('');

  useEffect(() => {
    setReceiptDescription(receipt.description);
  }, [receipt]);

  return (
    <ThemeProvider theme={ThemeOption}>
      <Box className={classes.blockInput}>
        <TextField
          className={classes.input}
          id="ReceiptEditDescription"
          label="Beschreibung"
          variant="filled"
          InputProps={{
            style: {
              background: '#F4F7FA',
              color: '#828282',
            },
          }}
          value={receiptDescription || ''}
          onChange={changeReceipt}
          name="description"
          required
          error={receipt.description ? false : true}
          helperText={receipt.description ? '' : 'falsche Eingabe'}
        />
      </Box>
    </ThemeProvider>
  );
}

export default ReceiptEditDescription;
