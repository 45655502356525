import { PagePathname } from '../../../../pages';
import Agentv2 from '../../../types/Agentv2.types';
import { AgentInputType } from '../../../types/AgentInputType.types';

export enum LocalStorageKeys {
  formDataCreateAgent = 'formData_createAgent',
  formDataCreateAgentFirstLoad = 'formData_createAgent_firstLoad',
}

const handleFormChange = (values: Agentv2 | AgentInputType) => {
  if (window.location.pathname === PagePathname.beraterErstellen) {
    localStorage.setItem(LocalStorageKeys.formDataCreateAgent, JSON.stringify(values));
    const formData = localStorage.getItem(LocalStorageKeys.formDataCreateAgent);
    if (formData) {
      const formDataFirst = JSON.parse(formData);

      if (formDataFirst.email_corp) {
        if (!localStorage.getItem(LocalStorageKeys.formDataCreateAgentFirstLoad)) {
          localStorage.setItem(LocalStorageKeys.formDataCreateAgentFirstLoad, JSON.stringify(values));
        }
      }
    }
  }
};
export default handleFormChange;
