import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import { useMutation } from '@apollo/client';
import { ChangeSet } from '@devexpress/dx-react-grid';
import saveAs from 'file-saver';
import { GridExporter } from '@devexpress/dx-react-grid-export';
//Local imports
import Table from '../../../../components/Table/Table';
import { columns, defaultFilterAndFormateValue } from './columnDefinition';
import DataContext from '../../../../context/DataProvider/DataContext';
import { Receipt as ReceiptType } from 'global-intern-types';
import LoadingDialog from '../../../../components/LoadingDialog';
import commitChanges from './commitChanges';
import columnsWidths from './columnsWidths';
import deleteReceiptQuery from '../../../../Apollo/mutations/deleteReceipt';
import AlertMessage from '../../../../components/AlertMessage/AlertMessage';

// @ts-expect-error
const onSaveXlsx = (workbook) => {
  // @ts-expect-error
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
  });
};

// @ts-expect-error
const onSaveFilterXlsx = (workbook) => {
  // @ts-expect-error
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'FilterDataGrid.xlsx');
  });
};

interface Row extends TableEditColumn.CellProps {
  row: ReceiptType;
}

const defaultFilter = [{ columnName: 'exported_at', value: defaultFilterAndFormateValue }];

function EditCellForComand(props: Row) {
  const { row } = props;
  if (row.exported_at) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <TableEditColumn.Cell {...props}>{null}</TableEditColumn.Cell>;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TableEditColumn.Cell {...props} />;
}

function AllReceiptsTable({ agentId }: { agentId?: string }) {
  const [row, setRow] = useState<ReceiptType[]>([]);
  const [deleteReceipt, { error, data }] = useMutation(deleteReceiptQuery);

  const { receipt, loaderFun, user, bookingAccount, tax } = useContext(DataContext);

  useEffect(() => {
    setRow(agentId ? receipt.filter((item) => item.agent_id === agentId) : receipt);
  }, [agentId, receipt]);

  useEffect(() => {
    if (tax.length === 0) {
      loaderFun.loadTax();
    }
    if (receipt.length === 0) {
      loaderFun.loadReceipt();
    }
  }, []);

  const refExportApp = useRef<typeof GridExporter>(null);
  const startExport = useCallback(() => {
    refExportApp.current?.exportGrid();
  }, [refExportApp]);

  const refExportFilter = useRef<typeof GridExporter>(null);
  const startFilterExport = useCallback(() => {
    refExportFilter.current?.exportGrid({ selectedOnly: true });
  }, [refExportFilter]);

  return (
    <>
      <Table
        defaultColumnWidths={columnsWidths}
        FilterRow
        row={row}
        columns={columns(user, bookingAccount, tax)}
        onCommitChanges={({ deleted }: ChangeSet) => commitChanges({ deleted, loaderFun, deleteReceipt, row })}
        EditCellForComand={EditCellForComand}
        editable
        showDeleteCommand
        pageSizes={[10, 20, 50, 200]}
        filteringState={defaultFilter}
        exporting={{
          all: {
            export: true,
            refConst: refExportApp,
            rows: row,
            columns: columns(user, bookingAccount, tax),
            onSave: onSaveXlsx,
            exportFunk: startExport,
          },
          filter: {
            export: true,
            refConst: refExportFilter,
            columns: columns(user, bookingAccount, tax),
            onSave: onSaveFilterXlsx,
            exportFunk: startFilterExport,
          },
        }}
      />
      <LoadingDialog open={receipt.length === 0} />
      <AlertMessage
        typeMessage={error ? 'error' : undefined}
        alertMessage={error ? 'Es ist ein Fehler aufgetreten' : undefined}
        isAlertMessage={!!error}
      />
      <AlertMessage
        typeMessage={data && !error ? 'success' : undefined}
        alertMessage={data && !error ? 'Beleg wurde erfolgreich gelöscht' : undefined}
        isAlertMessage={data && !error}
      />
    </>
  );
}

export default AllReceiptsTable;
