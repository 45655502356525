const columns = [
  { name: 'agent_id', title: 'Beraternummer' },
  { name: 'name', title: 'Name' },
  { name: 'surname', title: 'Vorname' },
  { name: 'email_corp', title: 'Geschäfts email' },
  { name: 'mobile', title: 'Handynummer' },
  { name: 'city', title: 'Stadt' },
  { name: 'address', title: 'Straße / Hausnummer' },
  { name: 'zip', title: 'PLZ' },
];

export default columns;
