import { Box, DialogTitle, IconButton } from '@material-ui/core';
import ListIcon from '@mui/icons-material/List';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

interface Props {
  row?: any;
}

function OptionButton({ row }: Props): JSX.Element {
  return (
    <>
      <IconButton onClick={() => {}} disabled>
        <ListIcon />
      </IconButton>
    </>
  );
}

export default OptionButton;
