import * as Yup from 'yup';
import { CountryCode, parsePhoneNumber, ParseError, validatePhoneNumberLength } from 'libphonenumber-js';
import { Agent, AgentStatus } from '../Agent.types';
import { Laender, COUNTRYCODE } from '../Country.types';
import AgentInput from '../initValues/AgentInput';

function validateStartDate(userInputStartdate: string | undefined): boolean {
  if (userInputStartdate) {
    const inputIsADate = new Date(userInputStartdate);
    if (!inputIsADate) return false;

    const minYear = new Date('2000-12-31').getFullYear();
    const maxYear = new Date('2100-12-31').getFullYear();
    const inputDateYear = inputIsADate.getFullYear();
    if (inputDateYear < minYear || inputDateYear > maxYear) return false;

    return true;
  }
  if (userInputStartdate === AgentInput.start_date) {
    return true;
  }
  return false;
}

function validateIBAN(iban: string | undefined): boolean {
  if (iban) {
    const ibanRemoveSpaces = iban.replace(/\s/g, '');
    const countryCode = ibanRemoveSpaces.slice(0, 2);
    const countryIbanLength: any = {
      DE: 22,
      IT: 27,
      CH: 21,
      AT: 20,
      ES: 24,
      LT: 20,
    };

    if (!countryIbanLength[countryCode] || ibanRemoveSpaces.length !== countryIbanLength[countryCode]) {
      return false;
    }

    const formattedIban = ibanRemoveSpaces.slice(4) + ibanRemoveSpaces.slice(0, 4);
    let formattedIbanNumeric = '';

    for (let i = 0; i < formattedIban.length; i++) {
      const charCode = formattedIban.charCodeAt(i);
      if (charCode >= 65 && charCode <= 90) {
        formattedIbanNumeric += (charCode - 55).toString();
      } else {
        formattedIbanNumeric += formattedIban[i];
      }
    }

    return BigInt(formattedIbanNumeric) % BigInt(97) === BigInt(1);
  }
  return false;
}

const validatePhoneFax = Yup.string()
  .min(2, 'min. 2 Zeichen')
  .test('uniq', 'zu Lang', (value, context) => {
    if (value) {
      let isValid = false;
      try {
        const isToLong = validatePhoneNumberLength(
          value,
          COUNTRYCODE[context.parent.country_id as Laender].code as CountryCode,
        );
        isValid = !(isToLong === 'TOO_LONG');
      } catch (parseError) {
        if (parseError instanceof ParseError) {
          // eslint-disable-next-line no-console
          console.error(`status`, parseError.message);
        }
      }
      return isValid;
    }
    return true;
  })
  .test('uniq', 'Nummer ungültig', (value, context) => {
    if (value) {
      let isValid = false;
      try {
        const formatetPhoneNumber = parsePhoneNumber(
          value,
          COUNTRYCODE[context.parent.country_id as Laender].code as CountryCode,
        );
        isValid = formatetPhoneNumber.isValid();
      } catch (parseError) {
        if (parseError instanceof ParseError) {
          // eslint-disable-next-line no-console
          console.error(`status`, parseError.message);
        }
      }
      return isValid;
    }
    return true;
  })
  .nullable();

const stringMax128required = Yup.string().max(128, 'max. 128 Zeichen').required('Pflichtfeld');
const stringMax128nullable = Yup.string().max(128, 'max. 128 Zeichen').nullable();
const stringNullable = Yup.string().nullable();

const stringMax128when = Yup.string()
  .max(128, 'max 128 Zeichen')
  .when('status_id', {
    is: AgentStatus.active,
    then: Yup.string().max(128, 'max 128 Zeichen').required('Pflichtfeld').typeError('Pflichtfeld'),
  })
  .when('status_id', {
    is: AgentStatus.inProcess,
    then: Yup.string().nullable(),
  })
  .when('status_id', {
    is: AgentStatus.dismissed,
    then: Yup.string().nullable(),
  });
const stringWhen = Yup.string()
  .when('status_id', {
    is: AgentStatus.active,
    then: Yup.string()
      .test('uniq', 'Ungültiges Datum', (value) => {
        return validateStartDate(value);
      })
      .required('Pflichtfeld')
      .typeError('Pflichtfeld'),
  })
  .when('status_id', {
    is: AgentStatus.inProcess,
    then: Yup.string()
      .test('uniq', 'Ungültiges Datum', (value) => {
        return validateStartDate(value);
      })
      .nullable(),
  })
  .when('status_id', {
    is: AgentStatus.dismissed,
    then: Yup.string().nullable(),
  });

const numberMax10required = Yup.number()
  .integer('Keine Kommazahlen')
  .max(10)
  .required('Pflichtfeld')
  .typeError('Pflichtfeld');

function ValidationSchema(agents: Agent[]) {
  const agentInProcessValidationSchema = Yup.object().shape({
    name: stringMax128required,
    surname: stringMax128required,
    agent_id: Yup.number().integer('Keine Kommazahlen').max(9999, 'max 4 Zeichen').nullable(),
    birthday: Yup.string().required('Pflichtfeld'),
    status_id: Yup.number().min(1).max(20).required('Pflichtfeld'),
    is_male: Yup.number().min(0).max(1).required('Pflichtfeld'),
    notes: stringNullable,
    phone: validatePhoneFax,
    mobile: validatePhoneFax,
    fax: validatePhoneFax,
    email_corp: Yup.string()
      .max(128, 'max 128 Zeichen')
      .required('Pflichtfeld')
      .test('uniq', 'Email ist bereits vorhanden', (value, context) => {
        const corpEmailSearch = agents.filter((item) => item.email_corp === value);
        if (context.parent.agent_id === null && corpEmailSearch.length > 0) {
          return false;
        }
        return true;
      })
      .test('uniq', 'Validierung fehlgeschlagen siehe Info', (value) => {
        if (value) {
          if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
            return true;
          }
        }
        return false;
      })
      .nullable(),
    email_private: Yup.string()
      .max(128, 'max 128 Zeichen')
      .required('Pflichtfeld')
      .test('uniq', 'Validierung fehlgeschlagen siehe Info', (value) => {
        if (value) {
          if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
            return true;
          }
        }
        return false;
      }),
    rank_id: numberMax10required,
    level_id: numberMax10required,
    branch_office_id: numberMax10required,
    jackplus_id: Yup.number()
      .integer('Keine Kommazahlen')
      .max(999999, 'max. 6 Stellen')
      .test('uniq', 'bereits vorhanden', (value, context) => {
        let porbeArr = agents;
        if (context.parent.agent_id) {
          porbeArr = agents.filter((agent) => agent.agent_id !== context.parent.agent_id);
        }
        const otherJackId = porbeArr.find((agent) => value && agent.jackplus_id === value);
        if (otherJackId) return false;
        return true;
      })
      .nullable()
      .when('status_id', {
        is: AgentStatus.active,
        then: (schema) => schema.required('Pflichtfeld'),
      }),
    agent_account_nr: Yup.number()
      .max(9999999, 'max. 7 Stellen')
      .integer('Keine Kommazahlen')
      .test('uniq', 'bereits vorhanden', (value, context) => {
        let probeArrAccountNr = agents;
        if (context.parent.agent_id) {
          probeArrAccountNr = agents.filter((agent) => agent.agent_id !== context.parent.agent_id);
        }
        const otherAgentAccountNr = probeArrAccountNr.find((agent) => value && agent.agent_account_nr === value);
        if (otherAgentAccountNr) return false;
        return true;
      })
      .nullable()
      .when('status_id', {
        is: AgentStatus.active,
        then: (schema) => schema.required('Pflichtfeld'),
      }),
    contract_type_id: Yup.number()
      .integer('Keine Kommazahlen')
      .max(10)
      .when('status_id', {
        is: AgentStatus.active,
        then: Yup.number().max(10).required('Pflichtfeld').typeError('Pflichtfeld'),
      })
      .when('status_id', {
        is: AgentStatus.inProcess,
        then: Yup.number().max(10).required('Pflichtfeld').typeError('Pflichtfeld'),
      })
      .required('Pflichtfeld')
      .typeError('Pflichtfeld'),
    address: stringMax128required,
    zip: Yup.string()
      .matches(/^[0-9]+$/, 'nur Zahlen')
      .min(1, 'min 1 Zeichen')
      .max(9, 'max 9 Zeichen')
      .required('Pflichtfeld'),
    city: stringMax128required,
    country_id: numberMax10required,
    address2: stringMax128nullable,
    zip2: Yup.string()
      .matches(/^[0-9]+$/, 'nur Zahlen')
      .min(1, 'max 1 Zeichen')
      .max(9, 'min 9 Zeichen')
      .nullable(),
    city2: stringMax128nullable,
    country2_id: Yup.number().integer('Keine Kommazahlen').max(10).nullable(),
    tax_nr: stringMax128when,
    ust_nr: stringMax128when,
    iban: Yup.string()
      .max(128, 'max 128 Zeichen')
      .when('status_id', {
        is: AgentStatus.active,
        then: Yup.string()
          .max(128, 'max 128 Zeichen')
          .required('Pflichtfeld')
          .test('uniq', 'Validierung fehlgeschlagen siehe Info', (value) => {
            return validateIBAN(value);
          })
          .typeError('Pflichtfeld'),
      })
      .when('status_id', {
        is: AgentStatus.inProcess,
        then: Yup.string().nullable(),
      })
      .when('status_id', {
        is: AgentStatus.dismissed,
        then: Yup.string().nullable(),
      }),
    bic: stringMax128when,
    business_owner: stringMax128nullable,
    start_date: stringWhen,
    is_small_business: Yup.number().min(0).max(1).required('Pflichtfeld'),
    bank_name: stringMax128nullable,
  });

  return agentInProcessValidationSchema;
}

export default ValidationSchema;
