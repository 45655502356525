import React from 'react';
import Grid from '@material-ui/core/Grid';
import ReceiptEdit from './components/ReceiptEdit';

function ReceiptPage() {
  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item xs={12}>
        <ReceiptEdit />
      </Grid>
    </Grid>
  );
}

export default ReceiptPage;
