import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import ThemeOption from '../../../../services/themeOption';

const useStyles = makeStyles((theme) => ({
  blockInput: {
    width: '100%',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
}));

function ReceiptEditPrice({ receipt, changeReceipt }) {
  const classes = useStyles();
  const [receiptPrice, setReceiptPrice] = useState('');

  useEffect(() => {
    setReceiptPrice(receipt.price);
  }, [receipt]);

  return (
    <ThemeProvider theme={ThemeOption}>
      <Box className={classes.blockInput}>
        <TextField
          className={classes.input}
          id="ReceiptEditPrice"
          label="Betrag"
          variant="filled"
          required
          InputProps={{
            style: {
              background: '#F4F7FA',
              color: '#828282',
            },
          }}
          value={receiptPrice || ''}
          type="number"
          onChange={changeReceipt}
          error={receipt.price ? false : true}
          helperText={receipt.price ? '' : 'falsche Eingabe'}
          name="price"
        />
      </Box>
    </ThemeProvider>
  );
}

export default ReceiptEditPrice;
