const InitOfficeValue = {
  id: null,
  title: '',
  domain: null,
  email_postfix: null,
  show_in_export_xml: 0,
  logo: null,
  title_for_worker_passport: null,
  min_agent_id: 0,
  max_agent_id: 0,
  is_default: false,
  zip: null,
  city: null,
  address: null,
  phone: null,
  fax: null,
  email: null,
  __typename: 'Branchoffice',
};

export default InitOfficeValue;
