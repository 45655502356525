import React, { useCallback, useContext, useMemo } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Table as TableGrid } from '@devexpress/dx-react-grid-material-ui';
import Table from '../Table';
import columnDef from './columnDef';
import DataContext from '../../context/DataProvider/DataContext';
import InfoButton from '../InfoButton';
import i18n from './i18n';
import getMonthName from './services/getMonthName';
import sortByBirthdayMonth from './services/sortByBirthdayMonth';
import { AgentStatus } from '../../types/Agent.types';

function BirthdaysOfMonth() {
  const { agents } = useContext(DataContext);
  const history = useHistory();

  const filteredAgentsOnBirthday = useMemo(
    () =>
      agents.map((agent) => {
        const agentBirthdate = new Date(agent.birthday);
        const agentBirthdateMonth = getMonthName(agentBirthdate.getMonth());
        let agentWithMontOfBirthday = { ...agent, month_of_birthday: agentBirthdateMonth };
        return agentWithMontOfBirthday;
      }),
    [agents],
  );

  const TableRow = useCallback(
    ({ row, children, tableRow }: TableGrid.DataRowProps) => (
      <TableGrid.Row
        key={Math.random()}
        tableRow={tableRow}
        row={row}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        onClick={() => {
          history.push(`/agent/${row.agent_id}`);
        }}
        style={{ cursor: 'pointer' }}
      />
    ),
    [],
  );

  const grouping = [{ columnName: 'month_of_birthday' }];

  return (
    <Card sx={{ minWidth: '800px', width: '30vw' }}>
      <CardContent>
        <Box display="flex">
          <Typography gutterBottom variant="h5" component="div">
            Geburtstage
          </Typography>
          <InfoButton tooltiptext={i18n.infoText} />
        </Box>
        <Table
          rowComponent={TableRow}
          pageSize={0}
          columns={columnDef}
          row={sortByBirthdayMonth(
            filteredAgentsOnBirthday.filter((agent) => agent.status.id !== AgentStatus.dismissed),
          )}
          groupingCollumName={grouping}
          groupingTable={true}
        />
      </CardContent>
    </Card>
  );
}

export default BirthdaysOfMonth;
