import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ThemeOption from '../../../../services/themeOption';

const useStyles = makeStyles((theme) => ({
  blockInput: {
    width: '100%',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formInput: {
    background: '#F4F7FA',
    color: '#828282',
    minWidth: 185,
  },
}));

function ReceiptEditBookingAccount({ receipt, bookingAccountList, changeReceipt }) {
  const classes = useStyles();
  const [receiptBookingAccount, setReceiptBookingAccount] = useState(0);

  useEffect(() => {
    setReceiptBookingAccount(receipt.booking_account_id);
  }, [receipt]);

  const listItems = bookingAccountList.map((item) => (
    <MenuItem key={item.id} value={item.id}>
      {item.title}
    </MenuItem>
  ));

  return (
    <ThemeProvider theme={ThemeOption}>
      <Box className={classes.blockInput}>
        <FormControl
          variant="filled"
          required
          className={classes.formControl}
          error={receipt.booking_account_id ? false : true}
        >
          <InputLabel id="ReceiptEditBookingAccountLabel">Buchungskonto</InputLabel>
          <Select
            labelId="ReceiptEditBookingAccountLabel"
            id="ReceiptEditBookingAccount"
            value={receiptBookingAccount || ''}
            onChange={changeReceipt}
            className={classes.formInput}
            name="booking_account_id"
          >
            {listItems}
          </Select>
          {!receipt.booking_account_id && <FormHelperText>falsche Eingabe</FormHelperText>}
        </FormControl>
      </Box>
    </ThemeProvider>
  );
}

export default ReceiptEditBookingAccount;
