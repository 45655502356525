import React from 'react';
import Grid from '@material-ui/core/Grid';
import MontlyAccounting from '../../../components/MontlyAccounting/MontlyAccouting';

function MontlyAccountingPage() {
  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item xs={12}>
        <MontlyAccounting />
      </Grid>
    </Grid>
  );
}

export default MontlyAccountingPage;
