import React from 'react';
import ErrorBoundaries from '../../../components/ErrorBoundaries';
import AllBrachofficeTable from './components/AllBrachofficeTable/AllBranchofficeTable';

function BranchofficePage() {
  return (
    <ErrorBoundaries>
      <AllBrachofficeTable />
    </ErrorBoundaries>
  );
}

export default BranchofficePage;
