import deleteUnnecessarySpaces from '../../../../services/deleteUnnecessarySpaces';

const commitChanges = async ({ added, changed, rows, loaderFun, createCountry, updateCountry }) => {
  let changedRows;

  if (added) {
    const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
    changedRows = [
      ...rows,
      ...added.map((row, index) => ({
        id: startingAddedId + index,
        ...row,
      })),
    ];
    const currentCountry = {
      title: deleteUnnecessarySpaces(changedRows[rows.length].title),
      is_default: changedRows[rows.length].is_default === 'Yes' ? true : false,
    };

    if (currentCountry) {
      createCountry({ variables: { variables: currentCountry } }).then((result) => {
        if (result.data.createCountry) loaderFun.loadCountry();
      });
    }
  }

  if (changed) {
    const currentRows = [...rows];
    changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    let editRowID;
    let editRow = {};
    for (let i = 0; i < currentRows.length; i += 1) {
      if (currentRows[i] !== changedRows[i]) {
        editRowID = changedRows[i].id;
        editRow = {
          title: deleteUnnecessarySpaces(changedRows[i].title),
          is_default: changedRows[i].is_default,
        };
      }
    }

    if (editRow) {
      updateCountry({ variables: { variables: editRow, id: editRowID } }).then((result) => {
        if (result.data.updateCountry) loaderFun.loadCountry();
      });
    }
  }
};

export default commitChanges;
