import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

const ThemeOption = createTheme({
  root: {
    '&$disabled': {
      color: 'white',
    },
  },
  palette: {
    primary: {
      light: '#56CCF2',
      main: '#56CCF2',
      dark: '#56CCF2',
    },
    secondary: {
      light: '#F2F5F8',
      lightMain: '#CBD1DE',
      main: '#404D66',
      dark: '#364257',
    },
  },
  loginPage: {
    background: '#ffffff',
    formBackground: '#ffffff',
  },
  header: {
    headerHeight: 64,
    headerPageColor: '#333333',
    headerBackgroundColor: '#ffffff',
  },
  aside: {
    asideHeaderColorBurger: '#ffffff',
    headerAsideColor: '#CBD1DE',
    asideHeaderBackgroundColor: '#364257',
    asideHeaderChevronColor: '#ffffff',
    asideNavTextColor: '#CBD1DE',
    asideNavColorTranslateDe: '#E0E0E0',
    asideNavColorTranslateEn: '#9E9E9E',
    asideHeaderHeight: 64,
    asideBackgroundColor: '#404D66',
    asideHoverBgColor: 'rgba(0, 0, 0, 0.15)',
    fontAsideHeader: '24px',
  },
  user: {
    userTextColor: 'rgba(0, 0, 0, 0.36)',
    userTextColorLevel: 'rgba(0, 0, 0, 0.54)',
    userTextColorPersonal: ' #4F4F4F',
  },
  userInfo: {
    userLightBg: '#F2F2F2',
    userBgColor: '#ffffff',
  },
  contentFolder: {
    contentFolderBlack: '#000000',
    contentFolderGrey: 'rgba(0, 0, 0, 0.66)',
    contentFolderLightGrey: 'rgba(0, 0, 0, 0.36)',
  },
  widget: {
    widgetBackgroundColor: '#FFFFFF',
  },
  overrides: {
    MuiBadge: {
      colorPrimary: {
        color: 'white',
        backgroundColor: '#E91E63',
      },
    },
    MuiSvgIcon: {
      // Name of the rule
      colorPrimary: {
        // Some CSS
        color: '#56CCF2',
      },
      colorSecondary: {
        color: '#CBD1DE',
      },
      fontSizeLarge: {
        width: '50px',
        height: '50px',
      },
    },
    MuiTypography: {
      // Name of the rule
      h1: {
        fontSize: '36px',
        fontWeight: 'bold',
        color: '#828282',
        paddingBottom: '24px',
      },
      body2: {
        fontSize: '18px',
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.36)',
      },
      h5: {
        // Some CSS
        fontSize: '16px',
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.36)',
      },
    },
  },

  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      'Roboto',
    ].join(','),
    fontSize: 14,
  },
});
export default responsiveFontSizes(ThemeOption);
