import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLoadingContext } from '../../context/LoadingProvider/LoadingContext';
import { Box, DialogContent, DialogTitle, Dialog } from '@material-ui/core';

const Loading: React.FC = () => {
  const { loading } = useLoadingContext();
  if (loading) {
    return (
      <Dialog aria-labelledby="loading-dialog" open={loading}>
        <DialogTitle id="loading-dialog">Daten werden geladen...</DialogTitle>
        <DialogContent id="loading-dialog">
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  return null;
};

export default Loading;
