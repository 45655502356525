import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import DataContext from '../../../context/DataProvider/DataContext';

function HeaderSearch({ allAgents }) {
  const { brachOffices } = useContext(DataContext);
  const [brachIdForSearch, setBrachIdForSearch] = useState(null);
  const history = useHistory();

  const filterBrachOffice = (id) => {
    if (brachIdForSearch === null) {
      return allAgents;
    } else {
      return allAgents.filter((e) => {
        return e.branch_office.id === brachIdForSearch;
      });
    }
  };

  function choseBrachOfficeID(id) {
    setBrachIdForSearch(id);
  }

  const allAgentSearch = {
    options: filterBrachOffice(),
    getOptionLabel: (option) => `${option.agent_id} ${option.surname} ${option.name} `,
  };

  return (
    <Box justifyContent="space-between" ml={1} width="100%">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <Autocomplete
            {...allAgentSearch}
            id="SearchHeader"
            value={null}
            onChange={(event, value) => {
              history.push('/agent/' + value.agent_id);
            }}
            renderOption={(option) => (
              <>
                {option.status.id === 5 ? (
                  <span style={{ color: 'red' }}>
                    {' '}
                    {option.agent_id} {option.surname} {option.name}
                  </span>
                ) : (
                  <span>
                    {' '}
                    {option.agent_id} {option.surname} {option.name}
                  </span>
                )}
              </>
            )}
            renderInput={(props) => <TextField {...props} label="Agenten suchen" fullWidth />}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <SelectForBrachoffice brachoffices={brachOffices} choseBrachOfficeID={choseBrachOfficeID} />
        </Grid>
      </Grid>
    </Box>
  );
}
export default HeaderSearch;

const SelectForBrachoffice = ({ brachoffices, choseBrachOfficeID }) => (
  <>
    <FormControl fullWidth>
      <InputLabel>Firma auswählen</InputLabel>
      <Select
        onChange={(e) => {
          choseBrachOfficeID(e.target.value);
        }}
        autoWidth
        label="Firma wählen"
        defaultValue=""
      >
        {brachoffices.map((office) => (
          <MenuItem key={office.id} value={office.id}>
            {office.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </>
);
