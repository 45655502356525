import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import ThemeOption from '../../../../services/themeOption';

const useStyles = makeStyles((theme) => ({
  blockInput: {
    width: '100%',
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  formInput: {
    background: () => ThemeOption.palette.secondary.light,
    color: () => ThemeOption.palette.secondary.main,
    paddingTop: '8px',
    marginBottom: '20px',
  },
}));

function ReceiptEditAgent({ receipt, allAgents, changeReceiptAgent }) {
  const classes = useStyles();
  const [receiptAgent, setReceiptAgent] = useState([]);

  useEffect(() => {
    setReceiptAgent(receipt.agent_id);
  }, [receipt]);

  const allAgentSearch = {
    options: allAgents,
    getOptionLabel: (option) =>
      `${option.agent_id} ${option.surname} ${option.name} ${option.is_small_business === 1 ? 'Kleinunternehmer' : ''}`,
  };

  return (
    <Box className={classes.blockInput}>
      <Autocomplete
        {...allAgentSearch}
        id="ReceiptEditAgent"
        onChange={changeReceiptAgent}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.formInput}
            label="Berater wählen"
            value={receiptAgent}
            fullWidth
            name="agent_id"
            error={receipt.agent_id ? false : true}
            helperText={receipt.agent_id ? '' : 'falsche Eingabe'}
            required
          />
        )}
      />
    </Box>
  );
}

export default ReceiptEditAgent;
