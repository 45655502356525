interface Props {
  value: string | number;
}
const BooleanFormatter = ({ value }: Props): string => {
  // if (value === 'Yes' || value === 1) {
  //   return 'Yes';
  // } else {
  //   return 'No';
  // }
  return value ? 'Yes' : 'No';
};

export default BooleanFormatter;
