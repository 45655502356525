import React from 'react';
import { Button, Grid, Tooltip, IconButton } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
const BrachofficeBtnContainer = ({ brachofficeData, setBrancheOfficeFilter, allFilterReset }) => (
  <>
    {brachofficeData.length >= 2 ? (
      <Grid container spacing={2}>
        <ButtonForBrachoffice brachoffices={brachofficeData} setBrancheOfficeFilter={setBrancheOfficeFilter} />
        <Tooltip title="Alle Filter zurücksetzen" placement="top">
          <IconButton component="span" onClick={allFilterReset}>
            <BlockIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    ) : null}
  </>
);

export default BrachofficeBtnContainer;

const ButtonForBrachoffice = ({ brachoffices, setBrancheOfficeFilter }) =>
  brachoffices.map((office) => (
    <Grid item key={office.id}>
      <Button
        key={office.id}
        variant="contained"
        disableRipple
        onClick={() => {
          setBrancheOfficeFilter(office.title);
        }}
      >
        {office.title}
      </Button>
    </Grid>
  ));
