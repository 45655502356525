import React, { useState, useEffect } from 'react';
import GroupSirvicesItem from './GroupSirvicesItem';
import normalizeAllServices from './services/normalizeAllServices';
import normalizeServicesById from './services/normalizeServicesById';
import requestToServer from '../../../services/requestToServer';
import getAllServices from '../../../services/requestToServerSchemas/querySchemas/getAllServices';
import getServicesByAgentID from '../../../services/requestToServerSchemas/querySchemas/getServicesByAgentID';

function AgentServices({ agentId }) {
  const [agentServices, setAgentServices] = useState([]);
  const [singleService, setSingleService] = useState([
    {
      id: '2',
      title: '2sadfs hda  asdsa',
      default_price: 19,
      type: 'SingleService',
      tax: {
        id: 2,
        code: 'U010',
        percent: 19,
      },
      fixed_start_month: null,
      booking_account: {
        id: 6,
        title: 'Beraterausweis',
        booking_account_nr: 860051,
        external_reference: 7974,
      },
    },
  ]);

  const [monthlyService, setMonthlyService] = useState([
    {
      id: '1',
      title: '1sdfs dfdsfsfdsdfds fgfddf',
      default_price: 19,
      type: 'MonthlyService',
      tax: {
        id: 2,
        code: 'U010',
        percent: 19,
      },
      fixed_start_month: null,
      booking_account: {
        id: 6,
        title: 'Beraterausweis',
        booking_account_nr: 860051,
        external_reference: 7974,
      },
    },
  ]);
  const [yearlyService, setYearlyService] = useState([
    {
      id: '3',
      title: '3sdljf dsadfasf ldsjfsad',
      default_price: 19,
      type: 'YearlyService',
      tax: {
        id: 2,
        code: 'U010',
        percent: 19,
      },
      fixed_start_month: null,
      booking_account: {
        id: 6,
        title: 'Beraterausweis',
        booking_account_nr: 860051,
        external_reference: 7974,
      },
    },
  ]);

  useEffect(() => {
    const getData = async () => {
      const data = await requestToServer('query', [getAllServices, getServicesByAgentID(agentId)]);

      if (data.getAllServices) {
        const normalizedServicesData = await normalizeAllServices(data.getAllServices);
        setSingleService(normalizedServicesData.filter((item) => item.type === 'SingleService'));
        setMonthlyService(normalizedServicesData.filter((item) => item.type === 'MonthlyService'));
        setYearlyService(normalizedServicesData.filter((item) => item.type === 'YearlyService'));
      }
      if (data.getServicesByAgentID) {
        const normalizedServicesDataById = await normalizeServicesById(data.getServicesByAgentID);
        setAgentServices(normalizedServicesDataById);
      }
    };
    getData();
  }, [agentId]);

  return (
    <>
      <GroupSirvicesItem
        titleGroup="EinmaligeLeistungen"
        servicesList={singleService}
        agentServices={agentServices}
        agentId={agentId}
      />
      <GroupSirvicesItem
        titleGroup="MontlicheLeistungen"
        servicesList={monthlyService}
        agentServices={agentServices}
        agentId={agentId}
      />
      <GroupSirvicesItem
        titleGroup="JährlicheLeistungen"
        servicesList={yearlyService}
        agentServices={agentServices}
        agentId={agentId}
      />
    </>
  );
}

export default AgentServices;
