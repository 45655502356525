import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Update from '@material-ui/icons/Update';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import ThemeOption from '../../../services/themeOption';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  ItemProject: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    background: '#ffffff',
    marginLeft: '10px',
    marginRight: '10px',
    marginBottom: '25px',
    paddingRight: '16px',
    paggingLeft: '16px',
  },
  infoBlockProject: {
    width: '120px',
  },
  headerProject: {
    paddingTop: '3px',
    fontSize: '1.2em',
  },
  dateProject: {
    fontSize: '0.8em',
  },
  controlBlock: {
    width: '80px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statusTrueProject: {
    width: '20px',
    height: '20px',
    background: 'red',
    borderRadius: '50%',
  },
  statusFalseProject: {
    width: '20px',
    height: '20px',
    background: 'green',
    borderRadius: '50%',
  },
  iconButton: {
    width: '50px',
    height: '50px',
  },
  icon: {
    fontSize: '40px',
    color: '#404D66',
  },
}));

function ItemProject({ title, date, status, refreshDate }) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={ThemeOption}>
      <Box className={classes.ItemProject}>
        <Box className={classes.infoBlockProject}>
          <Typography component="h3" className={classes.headerProject}>
            {title} {status}
          </Typography>
          <Typography component="span" className={classes.dateProject}>
            {date}
          </Typography>
        </Box>
        <div className={classes.controlBlock}>
          {!status && <div className={classes.statusTrueProject}></div>}
          {status && <div className={classes.statusFalseProject}></div>}
          <Tooltip title={'Send Agent to ' + title} arrow>
            <IconButton
              className={classes.iconButton}
              onClick={() => {
                refreshDate(title);
              }}
              component="span"
            >
              <Update className={classes.icon} />
            </IconButton>
          </Tooltip>
        </div>
      </Box>
    </ThemeProvider>
  );
}

export default ItemProject;
