import React, { useRef } from 'react';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

interface Props {
  value: boolean;
  onValueChange: (value: boolean) => void;
}

const BooleanEditor = ({ value, onValueChange }: Props): JSX.Element => {
  const { current: initialValue } = useRef(value);
  if (initialValue !== undefined) {
    return (
      <Select
        input={<Input />}
        value={value ? 'Yes' : 'No'}
        onChange={(event) => onValueChange(event.target.value === 'Yes')}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </Select>
    );
  } else {
    return (
      <FormControl error={value === undefined ? true : false}>
        <Select input={<Input />} value={value} onChange={(event) => onValueChange(event.target.value === 'Yes')}>
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
        <FormHelperText>{value === undefined ? 'Pflichtfeld' : ''}</FormHelperText>
      </FormControl>
    );
  }
};

export default BooleanEditor;
