import React, { useState, useContext, useRef, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as Logo } from './logo.svg';
import DataContext from '../../context/DataProvider/DataContext';
import UserContext from '../../context/UserProvider/UserContext';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formInput: {
    background: '#ffffff',
  },
  submit: {
    margin: theme.spacing(4, 0, 4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.light,
    '&:hover': { background: theme.palette.secondary.dark },
    fontWeight: 'bold',
  },
  footerFormSpan: {
    display: 'inline-block',
    fontSize: 12,
    textAlign: 'center',
  },
  footerFormA: {
    color: () => theme.palette.secondary.dark,
  },
}));

function Login() {
  const { reloadData } = useContext(DataContext);
  const { login } = useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const userName = useRef<HTMLInputElement | null>();
  const userPassword = useRef<HTMLInputElement | null>();

  const handleSubmit = useCallback((e: React.SyntheticEvent) => {
    setIsLoading(true);
    e.preventDefault();
    if (userName.current && userPassword.current) {
      login(userName.current.value, userPassword.current.value)
        .then((isAuthUser) => {
          if (isAuthUser) {
            setIsLoading(false);
            reloadData(); // TODO should goes to UserProvider
            history.push('/');
          } else {
            setIsLoading(false);
            setError(true);
          }
        })
        .catch(() => {
          setIsLoading(false);
          setError(true);
        });
    }
  }, []);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100vw" height="100vh">
      <Box width={300} height={300}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Logo />
        </Box>
        <Paper elevation={3}>
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              variant="filled"
              fullWidth
              inputRef={userName}
              id="login"
              label="Login"
              name="login"
              autoComplete="login"
              autoFocus
              color="secondary"
            />
            <TextField
              variant="filled"
              fullWidth
              inputRef={userPassword}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              color="secondary"
            />
            {error && (
              <Box width="100%" display="flex" justifyContent="center">
                <Typography color="error">Falsche Zugangsdaten</Typography>
              </Box>
            )}
            <Button
              disabled={isLoading}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {isLoading && <CircularProgress size="30px" />}
              {!isLoading && 'Einlogen'}
            </Button>
            <Grid container>
              <Grid item>
                <Box mt={2} mb={2}>
                  <Typography className={classes.footerFormSpan}>
                    {`Password vergessen?, bitte kontaktieren Sie `}
                    <a href="mailto:edv@rita.ag">edv@rita.ag</a>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
    </Box>
  );
}

export default Login;
