import React from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

function PasswordEditor({ value, onValueChange }: Props) {
  return (
    <Box>
      <TextField
        value={value || ''}
        onChange={(event) => onValueChange(event.target.value)}
        type="password"
        error={!value}
        helperText={value ? '' : 'Incorrect entry'}
      />
    </Box>
  );
}

export default PasswordEditor;

