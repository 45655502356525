import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useQuery } from '@apollo/client';
import ReceiptInput from '../../../../types/ReceiptInput.types';
import { MONTHS } from '../../../../services/config/CONST';
import getClosingDataPeriod from '../../../../Apollo/queries/getClosingDataPayrollPeriod';

const useStyles = makeStyles((theme) => ({
  blockInput: {
    width: '100%',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formInput: {
    background: '#F4F7FA',
    color: '#828282',
    minWidth: 185,
  },
}));

interface Props {
  receipt: ReceiptInput;
  changeReceipt: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => void;
}

function ReceiptEditMonth({ receipt, changeReceipt }: Props) {
  const { data } = useQuery(getClosingDataPeriod);
  const classes = useStyles();
  const [receiptMonth, setReceiptMonth] = useState(0);
  const [dateOfLastPayloadArr, setDateOfLastPayloadArr] = useState('');

  useEffect(() => {
    setDateOfLastPayloadArr(data?.getClosingDataPayrollPeriod);
  }, [data]);

  useEffect(() => {
    setReceiptMonth(receipt.month);
  }, [receipt]);

  const lastClosedPeriodMonth = new Date(dateOfLastPayloadArr).getUTCMonth();
  const filteredMonth = lastClosedPeriodMonth === 12 ? MONTHS : MONTHS.slice(lastClosedPeriodMonth, 12);
  const listItems = filteredMonth.map((item) => (
    <MenuItem key={item.id} value={item.id}>
      {item.title}
    </MenuItem>
  ));
  return (
    <Box className={classes.blockInput}>
      <FormControl variant="filled" required className={classes.formControl} error={!receipt.month}>
        <InputLabel id="ReceiptEditMonthLabel">Monat</InputLabel>
        <Select
          labelId="ReceiptEditMonthLabel"
          id="ReceiptEditMonth"
          value={receiptMonth}
          onChange={changeReceipt}
          className={classes.formInput}
          name="month"
        >
          {listItems}
        </Select>
        {!receipt.month && <FormHelperText>falsche Eingabe</FormHelperText>}
      </FormControl>
    </Box>
  );
}

export default ReceiptEditMonth;
