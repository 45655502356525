import React, { useState } from 'react';
import { Box, DialogTitle } from '@material-ui/core';
import { useFormikContext, Formik } from 'formik';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Alert from '@material-ui/lab/Alert';
import DebounceTextField from '../../../components/DebounceTextfield';
import { AgentInputType } from '../../../types/AgentInputType.types';
import DismissedDialogValid from '../../../types/validSchemas/DismissedDialog.valid';

function AgentFormDismiss() {
  const { values: mainValues, setFieldValue } = useFormikContext<AgentInputType>();
  const [open, setOpen] = useState(false);

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  return (
    <Box sx={{ paddingTop: '5%' }}>
      <Button
        onClick={() => {
          handleOpen();
        }}
        variant="outlined"
        fullWidth
        disabled={!!mainValues.dismissed_at}
      >
        Berater Kündigen
      </Button>

      <Formik
        enableReinitialize
        validateOnMount
        initialValues={mainValues}
        onSubmit={(values) => {
          setFieldValue('dismissed_at', values.dismissed_at);
          setFieldValue('dismissal_reason', values.dismissal_reason);
        }}
        validationSchema={DismissedDialogValid}
      >
        {({ values, handleSubmit, handleChange, handleBlur, errors, isValid, resetForm, dirty }) => (
          <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle>Kündigung einstellen</DialogTitle>
            <DialogContent>
              <Box m={1} display="flex" flexDirection="column" minWidth="400px">
                <DebounceTextField
                  type="date"
                  label="Küdigungsdatum"
                  name="dismissed_at"
                  value={values.dismissed_at}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="standard"
                  helperText={errors.dismissed_at && errors.dismissed_at}
                  error={Boolean(errors.dismissed_at) || false}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box>
                <DebounceTextField
                  label="Kündigungs Notiz"
                  name="dismissal_reason"
                  value={values.dismissal_reason || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  multiline
                  rows={3}
                  helperText={errors.dismissal_reason && errors.dismissal_reason}
                  error={Boolean(errors.dismissal_reason) || false}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Alert variant="filled" severity="warning">
                  <div>{`Achtung: Alle Leistungen werden zum Monatsende des Kündigungsmonats geschlossen`}</div>
                  <div>{`Achtung: Statusänderung auf "gekündigt" erfolg automatisch zum letzten Tag des Kündigungsmonats`}</div>
                </Alert>
              </Box>
            </DialogContent>
            <DialogActions disableSpacing={false}>
              <Button
                autoFocus
                onClick={() => {
                  resetForm();
                  handleClose();
                }}
                variant="outlined"
              >
                Abbrechen
              </Button>

              <Button
                onClick={() => {
                  handleSubmit();
                  handleClose();
                }}
                variant="outlined"
                disabled={!isValid || !dirty}
              >
                Setzten
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </Box>
  );
}

export default AgentFormDismiss;
