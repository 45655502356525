import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

const NumberEditor = ({ value, onValueChange }: Props) => (
  <Box>
    <TextField
      value={value || ''}
      type="number"
      onChange={(event) => onValueChange(event.target.value)}
      error={value ? false : true}
      helperText={value ? '' : 'Enter unique range'}
    />
  </Box>
);

export default NumberEditor;
