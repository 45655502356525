import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

interface Props {
  onExecute: () => void;
  row?: any;
}

function EditButton({ onExecute, row }: Props) {
  return (
    <IconButton onClick={onExecute} title="Edit row">
      <EditIcon />
    </IconButton>
  );
}
export default EditButton;
