import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import addServiceToAgent from '../services/addServicesToAgent';

import { SERVICESTYPE } from '../../../../../services/config/CONST';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '90%',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function DialogAddServiceToAgent({ allServices, agent_id, refreshServicesById }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [servicesList, setServicesList] = useState([]);
  const [currentService, setCurrentService] = useState({
    id: '',
    service_id: '',
    price: '',
    description: '',
    type: '',
    date: '',
  });
  const [singleService, setSingleService] = useState([]);
  const [monthlyService, setMonthlyService] = useState([]);
  const [yearlyService, setYearlyService] = useState([]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const singleArray = [''];
    const monthlyArray = [''];
    const yearlyArray = [''];
    allServices.map((item) => {
      if (item.type === SERVICESTYPE[0]) {
        monthlyArray.push(item);
      } else if (item.type === SERVICESTYPE[1]) {
        singleArray.push(item);
      } else if (item.type === SERVICESTYPE[2]) {
        yearlyArray.push(item);
      }
      return {};
    });
    setSingleService(singleArray);
    setMonthlyService(monthlyArray);
    setYearlyService(yearlyArray);
    setServicesList(allServices);
  }, [allServices]);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event) => {
    const selectedService = servicesList.filter((item) => item.id === +event.target.value);
    setCurrentService(selectedService[0]);
  };

  const handleAddServiceToAgent = async () => {
    const response = await addServiceToAgent(currentService, agent_id);
    setOpen(false);
    if (response.data.addServiceToAgent) {
      refreshServicesById();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="secondary" variant="outlined" title="neue Leistung hinzufügen" onClick={handleClickOpen}>
        +
      </Button>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>neue Leistung hinzufügen</DialogTitle>
        <Tabs value={value} onChange={handleChange1} indicatorColor="primary" textColor="primary">
          <Tab label="Einmalig" />
          <Tab label="Monatlich" />
          <Tab label="Jährlich" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <DialogContent>
            <form className={classes.container}>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel htmlFor="filled-age-native-simple">wählen Sie eine Leistung</InputLabel>
                <Select
                  native
                  value={singleService.title}
                  onChange={handleChange}
                  inputProps={{
                    name: 'age',
                    id: 'filled-age-native-simple',
                  }}
                >
                  {singleService.map((service) => (
                    <option key={service.id} value={service.id}>
                      {service.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </form>
          </DialogContent>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DialogContent>
            <form className={classes.container}>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel htmlFor="filled-age-native-simple">wählen Sie eine Leistung</InputLabel>
                <Select
                  native
                  value={monthlyService.title}
                  onChange={handleChange}
                  inputProps={{
                    name: 'age',
                    id: 'filled-age-native-simple',
                  }}
                >
                  {monthlyService.map((service) => (
                    <option key={service.id} value={service.id}>
                      {service.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </form>
          </DialogContent>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DialogContent>
            <form className={classes.container}>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel htmlFor="filled-age-native-simple">wählen Sie eine Leistung</InputLabel>
                <Select
                  native
                  value={yearlyService.title}
                  onChange={handleChange}
                  inputProps={{
                    name: 'age',
                    id: 'filled-age-native-simple',
                  }}
                >
                  {yearlyService.map((service) => (
                    <option key={service.id} value={service.id}>
                      {service.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </form>
          </DialogContent>
        </TabPanel>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddServiceToAgent} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogAddServiceToAgent;
